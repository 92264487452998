import React, { useEffect } from 'react';

import classes from './UnifyCardDataPreview.module.css';
import { Bookmark1CObject, BookmarkTableViewTableFunctionType, BookmarkType } from '../../types/types';
import { combineQuery } from '../../utils/combineQuery';
import { getBookmarkPreviewDataThunk } from '../../store/catalogSlice';
import { useAppDispatch } from '../../hooks/hooks';
import Delimiter from '../../shared-front/Delimiter/Delimiter';
import Pagination from '../../shared-front/Pagination/Pagination';
import TableContainerDataPreview from './TableContainerDataPreview/TableContainerDataPreview';
import { START_PAGINATION_QUERY } from '../../utils/constants';

interface IUnifyCardDataPreviewProps {
  bookmark: BookmarkType;
}

const UnifyCardDataPreview: React.FC<IUnifyCardDataPreviewProps> = ({ bookmark }) => {
  const dispatch = useAppDispatch();
  const data = bookmark.data as Bookmark1CObject | BookmarkTableViewTableFunctionType | undefined;

  useEffect(() => {
    if (!data?.previewData) {
      const requestQuery = combineQuery([START_PAGINATION_QUERY]);
      dispatch(getBookmarkPreviewDataThunk({ bookmark, requestQuery }));
    }
  }, [dispatch, bookmark, data?.previewData]);

  const onChangePagination = (paginationQuery: string) => {
    const requestQuery = combineQuery([paginationQuery]);
    dispatch(getBookmarkPreviewDataThunk({ bookmark, requestQuery }));
  };

  return (
    <div className={classes.container}>
      <TableContainerDataPreview bookmark={bookmark} />
      <Delimiter />
      <div className={classes.paginationContainer}>
        <Pagination
          currentPageSize={data?.previewData?.meta.per_page}
          currentPage={data?.previewData?.meta.current_page}
          total={data?.previewData?.meta.total}
          onChangePagination={onChangePagination}
          lastPage={data?.previewData?.meta.last_page}
        />
      </div>
    </div>
  );
};

export default UnifyCardDataPreview;
