import { useAppDispatch, useAppSelector } from './hooks';
import { getQuerySearchValue } from '../utils/getQuerySearchValue';
import { getQueryPaginationFromFirstPage } from '../utils/getQueryPaginationFromFirstPage';
import { combineQuery } from '../utils/combineQuery';

import {
  getInfoSystemsThunk,
  selectorInfoSystems,
  selectorIsLoadingInfoSystems,
  selectorSearchValueInfoSystems,
} from '../store/infoSystemsSlice';
import { abortControllerInfoSystems } from '../api/InfoSystemsApi';
import { useCallback } from 'react';

interface IUseGetTechMetaDocuments {
  searchValue?: string;
  queryPagination?: string;
}

export const useGetInfoSystems = () => {
  const dispatch = useAppDispatch();
  const metaStore = useAppSelector(selectorInfoSystems)?.meta;
  const searchValueStore = useAppSelector(selectorSearchValueInfoSystems);
  const isLoadingInfoSystems = useAppSelector(selectorIsLoadingInfoSystems);

  return useCallback(
    ({ searchValue, queryPagination }: IUseGetTechMetaDocuments) => {
      const querySearchValue = getQuerySearchValue(
        searchValue ? searchValue : searchValue === '' ? '' : searchValueStore
      );

      const requestQuery = combineQuery([
        querySearchValue,
        queryPagination ? queryPagination : getQueryPaginationFromFirstPage(metaStore ? metaStore : undefined),
      ]);
      if (isLoadingInfoSystems) {
        abortControllerInfoSystems.abort();
        setTimeout(() => {
          dispatch(getInfoSystemsThunk(requestQuery));
        });
      } else {
        dispatch(getInfoSystemsThunk(requestQuery));
      }
    },
    [dispatch, metaStore, isLoadingInfoSystems, searchValueStore]
  );
};
