import React from 'react';

import classes from './SystemCard.module.css';
import CardsContentBlock from '../CardsContentBlock/CardsContentBlock';
import { Text } from '@consta/uikit/Text';
import { BookmarkType } from '../../types/types';
import { useExistedOrCreateNewBookmark } from '../../hooks/useExistedOrCreateNewBookmark';

interface ISystemCardProps {
  systemUuid?: string | null;
  systemName?: string | null;
  systemSourceIco?: string | null;
  systemSourceType?: string | null;
}

const SystemCard: React.FC<ISystemCardProps> = ({ systemName, systemUuid, systemSourceIco, systemSourceType }) => {
  const doExistedOrCreateNewBookmark = useExistedOrCreateNewBookmark();

  const onClick = () => {
    const bookmark = {
      label: systemName,
      type: 'system',
      slug: 'system',
      hash: systemUuid,
    } as BookmarkType;
    doExistedOrCreateNewBookmark(bookmark);
  };

  return (
    <div className={classes.container}>
      <CardsContentBlock title={'Интегрируемая система'}>
        <Text className={classes.linkToSystem} onClick={onClick}>
          {systemName ? systemName : '-'}
        </Text>
      </CardsContentBlock>
      {systemSourceType && (
        <CardsContentBlock title={'Тип источника'}>
          <div className={classes.sourceContainer}>
            {systemSourceIco && (
              <img
                src={`data:image/svg+xml;base64,${systemSourceIco}`}
                className={classes.sourceIco}
                alt="source icon"
              />
            )}
            <Text className={classes.source}>{systemSourceType}</Text>
          </div>
        </CardsContentBlock>
      )}
    </div>
  );
};

export default SystemCard;
