import { CreateNewConnectionsSliceType } from '../createNewConnectionSlice';
import { CreateNewConnectionParamsType } from '../../api/createNewConnectionApi';
import { ConnectionSettingsItemType } from '../../types/types';

export const getNewConnectionParams = (state: CreateNewConnectionsSliceType): CreateNewConnectionParamsType => {
  return {
    system: state.activeInfoSystem?._uuid ?? '',
    source_type: state.activeSourceType?._uuid ?? '',
    connector_type: state.activeConnectorType?._uuid ?? '',

    user: state.connectionUser ?? undefined,
    password: state.connectionPassword ?? undefined,
    host: state.ipAddress ?? undefined,
    port: state.connectionPort ? Number(state.connectionPort) : undefined,
    instance: state.connectionInstance ?? undefined,
    url: state.connectionUrl ?? undefined,

    connectionName: state.connectionName ?? undefined,
    connectionDescription: state.connectionDescription ?? undefined,

    general_settings: state.connectionGeneralSettings?.map((setting) =>
      setting.key === 'additional_settings'
        ? {
            ...setting,
            value: (setting.value as ConnectionSettingsItemType[]).filter((item) => item.isChecked),
          }
        : setting
    ),
    asset_settings: state.newConnectionSelectedAssets?.map((asset) => {
      const preAsset: { [key: string]: ConnectionSettingsItemType[] } = {};
      const assetName = asset.name ?? '';
      preAsset[assetName] = asset.settings
        ? asset.settings.map((setting) =>
            setting.key === 'additional_settings'
              ? {
                  ...setting,
                  value: (setting.value as ConnectionSettingsItemType[]).filter((item) => item.isChecked),
                }
              : setting
          )
        : [];
      return preAsset;
    }),

    scanSchedule: state.connectionScanSchedule,
    previewSchedule: state.connectionPreviewSchedule,
    version: state.connectionVersion,
  };
};
