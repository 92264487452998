import React, { useEffect, useRef, useState } from 'react';

import classes from './InfoSystemsPage.module.css';
import Delimiter from '../../shared-front/Delimiter/Delimiter';
import ISPControlRow from './ISPControlRow/ISPControlRow';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import ISPPaginationRow from './ISPPaginationRow/ISPPaginationRow';
import {
  clearSnackBarItems,
  deleteSnackBarItem,
  selectorIsErrorInfoSystems,
  selectorIsErrorSubSystems,
  selectorSnackBarItemsInfoSystems,
} from '../../store/infoSystemsSlice';
import ISPTable from './ISPTable/ISPTable';
import { LeftSideBarHandleType } from '../../shared-front/LeftSideBar/LeftSideBar';
import ISPRightSideBar from './ISPRightSideBar/ISPRightSideBar';
import ModalError from '../../Components/ModalError/ModalError';
import { SnackBar } from '@consta/uikit/SnackBar';
import { useGetInfoSystems } from '../../hooks/useGetInfoSystems';
import { selectorIsErrorCreateNewConnections } from '../../store/createNewConnectionSlice';

export enum IspRightSideBarEnum {
  TuneTable = 'tune_table',
  DisplaySystem = 'display_system',
  CreateSystem = 'create_system',
  EditSystem = 'edit_system',
}

const InfoSystemsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const refFirstUpload = useRef(false);
  const getInfoSystems = useGetInfoSystems();
  const refSidebar = useRef<LeftSideBarHandleType>(null);
  const infoSystemsError = useAppSelector(selectorIsErrorInfoSystems);
  const subSystemsError = useAppSelector(selectorIsErrorSubSystems);
  const createNewConnectionsError = useAppSelector(selectorIsErrorCreateNewConnections);

  const snackBarItems = useAppSelector(selectorSnackBarItemsInfoSystems).map((snackBarItem) => ({
    ...snackBarItem,
    onClose: (item: { key: string }) => {
      dispatch(deleteSnackBarItem({ key: item.key }));
    },
  }));

  const errorMessage = infoSystemsError
    ? infoSystemsError
    : subSystemsError
    ? subSystemsError
    : createNewConnectionsError;

  const [rightSideBarMode, setRightSideBarMode] = useState<IspRightSideBarEnum | undefined>(undefined);
  const [activeInfoSystemUuid, setActiveInfoSystemUuid] = useState<string | undefined>(undefined);

  useEffect(() => {
    dispatch(clearSnackBarItems());
  }, [dispatch]);

  useEffect(() => {
    if (!refFirstUpload.current) {
      refFirstUpload.current = true;
      getInfoSystems({});
    }
  }, [dispatch, getInfoSystems]);

  useEffect(() => {
    document.title = `ТехМет Инф.Системы`;
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <ISPControlRow refSidebar={refSidebar} setRightSideBarMode={setRightSideBarMode} />
        <Delimiter />
        <ISPTable
          refSidebar={refSidebar}
          setRightSideBarMode={setRightSideBarMode}
          activeInfoSystemUuid={activeInfoSystemUuid}
          setActiveInfoSystemUuid={setActiveInfoSystemUuid}
        />
      </div>
      <Delimiter />
      <ISPPaginationRow />
      <ISPRightSideBar
        refSidebar={refSidebar}
        rightSideBarMode={rightSideBarMode}
        setRightSideBarMode={setRightSideBarMode}
        activeInfoSystemUuid={activeInfoSystemUuid}
        setActiveInfoSystemUuid={setActiveInfoSystemUuid}
      />
      <ModalError errorMessage={errorMessage} />
      <div className={classes.snackBarContainer} style={{ zIndex: 1000 }}>
        <SnackBar items={snackBarItems} />
      </div>
    </div>
  );
};

export default InfoSystemsPage;
