import { useEffect, useState } from 'react';
import { useGetTechMetaDocuments } from './useGetTechMetaDocuments';
import { useAppSelector } from './hooks';
import { selectorCatalogActiveSection } from '../store/catalogSlice';

export const useCatalogItemsForDefaultStart = () => {
  const getTechMetaDocuments = useGetTechMetaDocuments();
  const [isUploadedDocuments, setIsUploadedDocuments] = useState(false);

  const catalogActiveSection = useAppSelector(selectorCatalogActiveSection);

  useEffect(() => {
    if (!isUploadedDocuments && catalogActiveSection) {
      setIsUploadedDocuments(true);
      getTechMetaDocuments({ catalogActiveSection });
    }
  }, [getTechMetaDocuments, isUploadedDocuments, catalogActiveSection]);
};
