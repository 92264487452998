import React from 'react';

import classes from './CatalogDataContainer.module.css';
import { useAppSelector } from '../../../../../hooks/hooks';
import {
  selectorCatalogActiveViewSelectorName,
  selectorCatalogItems,
  selectorCatalogsIsLoadingItems,
} from '../../../../../store/catalogSlice';
import CardsList from './CardsList/CardsList';
import { VIEW_SELECTOR_ITEMS } from '../ControlRow/ViewSelector/ViewSelector';
import TableCatalog from './TableCatalog/TableCatalog';
import { Loader } from '@consta/uikit/Loader';
import { Text } from '@consta/uikit/Text';

interface ICatalogDataContainerProps {}

const CatalogDataContainer: React.FC<ICatalogDataContainerProps> = () => {
  const isLoadingItems = useAppSelector(selectorCatalogsIsLoadingItems);
  const catalogItems = useAppSelector(selectorCatalogItems);
  const activeViewSelectorName = useAppSelector(selectorCatalogActiveViewSelectorName);
  const isTableView = activeViewSelectorName === VIEW_SELECTOR_ITEMS[1].name;

  return (
    <div className={`${classes.container} ${isTableView ? classes.containerTable : ''}`}>
      {isLoadingItems ? (
        <div style={{ width: '100%', marginTop: '30vh' }}>
          <Loader />
        </div>
      ) : catalogItems?.data.length ? (
        isTableView ? (
          <TableCatalog />
        ) : (
          <CardsList />
        )
      ) : (
        <Text className={classes.nothingFound}>{`Ничего не найдено`}</Text>
      )}
    </div>
  );
};

export default CatalogDataContainer;
