import React from 'react';

import classes from './LimitsForPreviewSettings.module.css';
import { ConnectionSettingsItemType } from '../../../../../../../../types/types';
import { Text } from '@consta/uikit/Text';
import { Radio } from '@consta/uikit/Radio';
import { TextField } from '@consta/uikit/TextField';
import { maxPreviewValue, minPreviewValue } from '../../../../../../../../utils/constants';

interface IPreviewSettingsProps {
  connectionSettings: ConnectionSettingsItemType[] | undefined;
  setConnectionSettings?: (connectionSettings: ConnectionSettingsItemType[] | undefined) => void;
  isDisplayMode?: boolean;
}

const LimitsForPreviewSettings: React.FC<IPreviewSettingsProps> = ({
  connectionSettings,
  setConnectionSettings,
  isDisplayMode,
}) => {
  const hasLimitsForPreview = connectionSettings?.find((setting) => setting.key === 'limits_for_preview');
  const limitsForPreviewValue = connectionSettings?.find((setting) => setting.key === 'limits_for_preview')?.value;
  const hasTextLimitsForPreview = typeof limitsForPreviewValue === 'string';
  const resetLimitsForPreview = () => {
    if (connectionSettings && setConnectionSettings) {
      setConnectionSettings(
        connectionSettings.map((setting) =>
          setting.key === 'limits_for_preview' ? { ...setting, value: null } : setting
        )
      );
    }
  };
  const setEmptyLimitsForPreview = () => {
    if (connectionSettings && setConnectionSettings) {
      setConnectionSettings(
        connectionSettings.map((setting) =>
          setting.key === 'limits_for_preview' ? { ...setting, value: '10000' } : setting
        )
      );
    }
  };
  const onChangeLimitsForPreview = ({ value }: { value: string | null }) => {
    if (connectionSettings && setConnectionSettings) {
      setConnectionSettings(
        connectionSettings.map((setting) =>
          setting.key === 'limits_for_preview' ? { ...setting, value: value ? value : '' } : setting
        )
      );
    }
  };

  const valueInRange = limitsForPreviewValue
    ? limitsForPreviewValue >= minPreviewValue && limitsForPreviewValue <= maxPreviewValue
    : true;

  return isDisplayMode || hasLimitsForPreview ? (
    <div className={classes.container}>
      <div className={classes.radioButtonGroup}>
        <Text className={classes.groupTitle}>{'Предпросмотр'}</Text>
        <div className={classes.buttonsRow}>
          <Radio
            label={'Включен'}
            checked={!!limitsForPreviewValue}
            onChange={setEmptyLimitsForPreview}
            disabled={isDisplayMode && !limitsForPreviewValue}
          />
          <Radio
            label={'Выключен'}
            checked={!limitsForPreviewValue}
            onChange={resetLimitsForPreview}
            disabled={isDisplayMode && !!limitsForPreviewValue}
          />
        </div>
        {hasTextLimitsForPreview ? (
          isDisplayMode ? (
            <Text className={classes.limitsText}>{`Количество строк предпросмотра : ${
              limitsForPreviewValue as string
            }`}</Text>
          ) : (
            <TextField
              id={'limitsForPreview'}
              type={'number'}
              min={minPreviewValue}
              max={maxPreviewValue}
              value={limitsForPreviewValue}
              onChange={onChangeLimitsForPreview}
              caption={`Доступное количество строк для сканирования предпросмотра от ${minPreviewValue}—${maxPreviewValue}`}
              status={valueInRange ? undefined : 'alert'}
            />
          )
        ) : null}
      </div>
    </div>
  ) : null;
};

export default LimitsForPreviewSettings;
