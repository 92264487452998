import axios from 'axios';
import { multiSignal } from './multiSignal';
import { abortControllerInfoSystems } from './InfoSystemsApi';
import { abortControllerConnections } from './connectionsApi';
import { abortControllerCheckNewConnection } from '../Pages/ConnectionsPage/CPRightSideBar/CPRightSideBarCreateConnection/CreateProgressStepSelector/CreateConnectionStepTwo/CreateConnectionCheckNewConnectionRow/CreateConnectionCheckNewConnectionRow';
import { abortControllerCheckEditedConnection } from '../Pages/ConnectionsPage/CPRightSideBar/CPRightSideBarEditConnection/EditConnectionContentSelector/EditConnectionSettings/EditCheckConnectionRow/EditCheckConnectionRow';
import { abortControllerEditedConnectionAssets } from '../Pages/ConnectionsPage/CPRightSideBar/CPRightSideBarEditConnection/EditConnectionContentSelector/EditConnectionSettings/EditConnectionFullAssetsRow/EditConnectionFullAssetsRow';
import { abortControllerCheckConnectionByUuid } from '../Pages/ConnectionsPage/CPRightSideBar/CPRightSideBarModalCheckConnection/CPRightSideBarModalCheckConnection';

export const instanceAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

instanceAxios.interceptors.request.use((config) => {
  const user = localStorage.getItem('user');
  config.headers['X-Auth-User'] = user ? user : '';

  return {
    ...config,
    signal: multiSignal(
      abortControllerInfoSystems.signal,
      abortControllerConnections.signal,
      abortControllerCheckNewConnection.signal,
      abortControllerCheckEditedConnection.signal,
      abortControllerCheckConnectionByUuid.signal,
      abortControllerEditedConnectionAssets.signal
    ),
  };
});
