import React, { useEffect, useRef, useState } from 'react';

import classes from './CardTabsRightInformer.module.css';
import iconInfoCircle from '../../../assets/CatalogPage/iconInfoCircle.svg';
import { Tooltip } from '@consta/uikit/TooltipCanary';

interface ICardTabsRightInformerProps {
  label: string;
}

const CardTabsRightInformer: React.FC<ICardTabsRightInformerProps> = ({ label }) => {
  const anchorRef = useRef<HTMLImageElement>(null);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [isToolTipVisible, setIsToolTipVisible] = useState(false);

  const onMouseEnter = () => {
    if (!isToolTipVisible) {
      timer.current = setTimeout(() => {
        setIsToolTipVisible(true);
      }, 500);
    }
  };

  const onMouseLeave = () => {
    if (isToolTipVisible) {
      setIsToolTipVisible(false);
    }
    clearTimeout(timer.current!);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current!);
    };
  }, []);

  return (
    <>
      <img
        ref={anchorRef}
        src={iconInfoCircle}
        className={classes.ico}
        alt={'infoIco'}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      {isToolTipVisible && (
        <Tooltip isOpen={true} size="l" anchorRef={anchorRef} direction={'downRight'} className={classes.toolTip}>
          {label}
        </Tooltip>
      )}
    </>
  );
};

export default CardTabsRightInformer;
