import React, { useState } from 'react';

import classes from './DisplayConnectionAdditional.module.css';
import { ConnectionDetailType } from '../../../../../../types/types';
import ConnectionSearchRow from '../../../../../../Components/ConnectionSearchRow/ConnectionSearchRow';
import ConnectionAssetsList from '../../../../../../Components/ConnectionAssetsList/ConnectionAssetsList';
import { CheckConnectionAssetType } from '../../../../../../api/serverResponse';

interface IDisplayConnectionAdditionalProps {
  connectionDetail: ConnectionDetailType | undefined;
}

const DisplayConnectionAdditional: React.FC<IDisplayConnectionAdditionalProps> = ({ connectionDetail }) => {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  return (
    <div className={classes.wrapper}>
      <ConnectionSearchRow
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        cardView={connectionDetail?.source_type?.card_view}
      />
      <div className={classes.container}>
        <ConnectionAssetsList
          searchValue={searchValue}
          assets={connectionDetail?.assets as CheckConnectionAssetType[] | undefined}
        />
      </div>
    </div>
  );
};

export default DisplayConnectionAdditional;
