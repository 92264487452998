import { ReactNode } from 'react';
import { ColumnGenericType } from '../types/types';
import { createTableColumn } from '../shared-front/Table/utils/createTableColumn';

export type ConnectionsTableDataType = {
  id?: string;
  systemName?: string;
  connectionName?: string | null;
  objects?: ReactNode | null;
  buttonScan?: ReactNode;
  buttonPreview?: ReactNode;
  sendToEdm?: string | null;

  description?: string | null;
  typeOfConnection?: string | null;
  typeOfSource?: string | null;
  _create_ts?: string | null;
  _create_user?: string | null;
  _alter_ts?: string | null;
  _alter_user?: string | null;
};

export const CONNECTIONS_TABLE_COLUMNS: ColumnGenericType[] = [
  createTableColumn<ConnectionsTableDataType>({
    accessorKey: 'systemName',
    title: 'Интегрируемая система',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<ConnectionsTableDataType>({
    accessorKey: 'connectionName',
    title: 'Подключение',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<ConnectionsTableDataType>({
    accessorKey: 'objects',
    title: 'Объекты',
    isShow: true,
    size: 300,
  }),
  createTableColumn<ConnectionsTableDataType>({
    accessorKey: 'buttonScan',
    title: 'Сканирование',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<ConnectionsTableDataType>({
    accessorKey: 'buttonPreview',
    title: 'Предпросмотр',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<ConnectionsTableDataType>({
    accessorKey: 'sendToEdm',
    title: 'Передает метаданные в EDM',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),

  createTableColumn<ConnectionsTableDataType>({
    accessorKey: 'description',
    title: 'Описание',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<ConnectionsTableDataType>({
    accessorKey: 'typeOfConnection',
    title: 'Тип подключения',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<ConnectionsTableDataType>({
    accessorKey: 'typeOfSource',
    title: 'Тип источника',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<ConnectionsTableDataType>({
    accessorKey: '_create_ts',
    title: 'Создано',
    textLinesNumber: 1,
    isShow: false,
    size: 300,
  }),
  createTableColumn<ConnectionsTableDataType>({
    accessorKey: '_create_user',
    title: 'Создал',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<ConnectionsTableDataType>({
    accessorKey: '_alter_ts',
    title: 'Изменено',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<ConnectionsTableDataType>({
    accessorKey: '_alter_user',
    title: 'Изменил',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
];

//////Основные поля:
// Информационная система (наименование информационной системы)
// Подключение (Наименование подключения)
// Объекты (В скрытом состоянии-количество объектов в рамках одного подключения. При нажатии на раскрытие наименования БД)
// Сканирование (кнопка запуска сканирования)
// Предпросмотр (кнопка запуска сканирования предпросмотра)
// Передает метаданные в EDM (пометка принимает значения Да/Нет)

//////Дополнительные поля:
// Описание (Описание подключения)
// Тип подключения (какой движок используется)
// Тип источника (ресурс)
// Создано
// Создал
// Изменено
// Изменил

// _uuid:
//   type: string
// format: uuid
// description: uuid, возможно понадобится для перехода на историю загрузок\
//       на запуски сканеров и т.д.
//   name:
// description: Колонка Подключение
// type: string
// description:
//   description: Колонка Описание
// type: string
// airflowConnId:
//   description: Подключение из airflow, не отображаем
// type: string
// source_name:
//   description: Если в поле assets_count=1, то отображаем это в поле Объекты
// type: string
// host:
//   description: Хост, не отображаем
// type: string
// system:
//   description: Информационная система
// type: object
// properties:
//   _uuid:
//     type: string
// format: uuid
// description: id
// name:
//   type: string
// description: Название которое отображается в колонке
// source_type:
//   description: Тип источника
// type: object
// properties:
//   _uuid:
//     type: string
// format: uuid
// description: id
// name:
//   type: string
// description: Название которое отображается в колонке
// connector_type:
//   description: Тип подключения
// type: object
// properties:
//   _uuid:
//     type: string
// format: uuid
// description: id
// name:
//   type: string
// description: Название которое отображается в колонке
// assets_count:
//   description: Количество отобржаемое в колонке Объекты. Если 1 отображаем \
//       содержимое поля source_name
// type: number
// scanner_id:
//   type: string
// description: Для кнопки Запуск в Колонке Сканирование
// preview_scanner_id:
//   type: string
// description: Для кнопки Запуск в Колонке Предпросмотр
// is_broken:
//   type: boolean
// description: Не отображаем, Поле где указывается, подключение создалось с ошибкой или нет
