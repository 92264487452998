import React, { useState } from 'react';

import classes from './EditConnectionFullAssetsRow.module.css';
import { Button } from '@consta/uikit/Button';
import { ProgressSpin } from '@consta/uikit/ProgressSpin';
import CustomBlueTextButton from '../../../../../../../Components/CustomBlueTextButton/CustomBlueTextButton';
import { Badge } from '@consta/uikit/Badge';
import { CheckNewConnectionParamsType } from '../../../../../../../api/createNewConnectionApi';
import {
  selectorEditConnection,
  setEditConnectionFullAssetsList,
} from '../../../../../../../store/editConnectionSlice';
import { serverApi } from '../../../../../../../api/serverApi';
import { pushSnackBarItemConnections } from '../../../../../../../store/connectionsSlice';
import { getRejectedValue } from '../../../../../../../store/rejectedValueHelper';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/hooks';

export let abortControllerEditedConnectionAssets = new AbortController();

interface IEditConnectionFullAssetsRowProps {}

const EditConnectionFullAssetsRow: React.FC<IEditConnectionFullAssetsRowProps> = () => {
  const dispatch = useAppDispatch();
  const editConnection = useAppSelector(selectorEditConnection);

  const [isLoadingAssets, setIsLoadingAssets] = useState(false);
  const [assetsLoadingResult, setAssetsLoadingResult] = useState<string | undefined>(undefined);

  const getEditConnectionAssets = async (params: CheckNewConnectionParamsType) => {
    if (!isLoadingAssets) {
      setIsLoadingAssets(true);
      assetsLoadingResult && setAssetsLoadingResult(undefined);
      try {
        const getAssetsResult = await serverApi.checkNewConnection(params);
        if (getAssetsResult.data) {
          setAssetsLoadingResult('БД актуальны');
          dispatch(setEditConnectionFullAssetsList(getAssetsResult.data.assets));
        } else {
          setAssetsLoadingResult('Ошибка получения списка БД');
          dispatch(
            pushSnackBarItemConnections({
              key: params.system + params.source_type + params.connector_type,
              message: 'Ошибка получения списка БД',
              status: 'alert',
              autoClose: true,
            })
          );
        }
      } catch (e: any) {
        if (e.name === 'CanceledError') {
          abortControllerEditedConnectionAssets = new AbortController();
          assetsLoadingResult && setAssetsLoadingResult(undefined);
        } else if (e.response?.data?.message) {
          setAssetsLoadingResult('Ошибка получения списка БД');
          dispatch(
            pushSnackBarItemConnections({
              key: params.system + params.source_type + params.connector_type,
              message: `Ошибка получения списка БД\n-${e.response.data.message}`,
              status: 'alert',
              autoClose: true,
            })
          );
        } else {
          setAssetsLoadingResult('Ошибка получения списка БД');
          dispatch(
            pushSnackBarItemConnections({
              key: params.system + params.source_type + params.connector_type,
              message: getRejectedValue('Ошибка получения списка БД\n- ', e),
              status: 'alert',
              autoClose: true,
            })
          );
        }
      } finally {
        setIsLoadingAssets(false);
      }
    }
  };

  const onClickCancelLoading = () => {
    setIsLoadingAssets(false);
    assetsLoadingResult && setAssetsLoadingResult(undefined);
    abortControllerEditedConnectionAssets.abort();
  };

  const onClickGetAssets = () => {
    if (editConnection) {
      getEditConnectionAssets({
        system: editConnection.system?._uuid ?? '',
        source_type: editConnection.source_type?._uuid ?? '',
        connector_type: editConnection.connector_type?._uuid ?? '',
        host: editConnection.host ?? undefined,
        port: Number(editConnection.port) ?? undefined,
        user: editConnection.user ?? undefined,
        password: editConnection.password ?? undefined,
        instance: editConnection.instance ?? undefined,
        uuid: editConnection._uuid ?? undefined,
      }).then();
    }
  };

  const isOdata = editConnection?.source_type?.card_view === 'odata';
  const isOracle = editConnection?.source_type?.card_view === 'oracle';

  if (isOdata || isOracle) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Button size={'s'} label={'Получить полный перечень БД'} onClick={onClickGetAssets} disabled={isLoadingAssets} />
      {isLoadingAssets ? (
        <div className={classes.buttonsGroup}>
          <ProgressSpin style={{ marginLeft: '8px', height: '26px' }} />
          <CustomBlueTextButton title={'Отмена'} onClick={onClickCancelLoading} />
        </div>
      ) : (
        assetsLoadingResult && (
          <div className={classes.badgeSection}>
            <Badge
              status={assetsLoadingResult.toLowerCase().includes('ошибка') ? 'error' : 'success'}
              label={assetsLoadingResult}
            />
          </div>
        )
      )}
    </div>
  );
};

export default EditConnectionFullAssetsRow;
