import { useAppDispatch, useAppSelector } from './hooks';
import {
  createNewBookmark,
  getBookmarkDataThunk,
  selectorCatalogActiveBookmarkKey,
  selectorCatalogBookmarks,
  setActiveBookmarkKey,
} from '../store/catalogSlice';
import { BookmarkType } from '../types/types';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

export const useExistedOrCreateNewBookmark = () => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [_, setSearchParams] = useSearchParams();
  const bookmarks = useAppSelector(selectorCatalogBookmarks);
  const catalogActiveBookmarkKey = useAppSelector(selectorCatalogActiveBookmarkKey);

  return useCallback(
    (bookmark: BookmarkType) => {
      const existedBookmark = bookmarks.find((bk) => bk.hash === bookmark.hash);
      if (existedBookmark) {
        if (existedBookmark.key !== catalogActiveBookmarkKey) {
          dispatch(setActiveBookmarkKey(existedBookmark.key));
        }
      } else {
        const searchParams = createSearchParams({
          type: `${bookmark.type}`,
          hash: `${bookmark.hash}`,
          slug: `${bookmark.slug}`,
        });
        setSearchParams(searchParams);
        dispatch(createNewBookmark(bookmark));
        dispatch(getBookmarkDataThunk(bookmark));
      }
    },
    [bookmarks, catalogActiveBookmarkKey, dispatch, setSearchParams]
  );
};
