import React from 'react';

import classes from './DisplayConnectionGeneral.module.css';
import { ConnectionType } from '../../../../../../types/types';
import { Text } from '@consta/uikit/Text';
import CollapseBlock from '../../../../../../Components/CollapseBlock/CollapseBlock';
import { formatDateDDMMYYYYHHMM } from '../../../../../../utils/formatDateDDMMYYYYHHMM';
import UserInfo from '../../../../../../Components/UserInfo/UserInfo';

interface IDisplayConnectionGeneralProps {
  connection: ConnectionType;
}

const DisplayConnectionGeneral: React.FC<IDisplayConnectionGeneralProps> = ({ connection }) => {
  return (
    <div className={classes.container}>
      <div className={classes.generalSection}>
        <div className={classes.sectionItem}>
          <Text className={classes.sectionItemTitle}>{`Наименование`}</Text>
          <Text className={classes.sectionItemValue}>{connection.name}</Text>
        </div>
        <div className={classes.sectionItem}>
          <Text className={classes.sectionItemTitle}>{`Описание`}</Text>
          <Text className={classes.sectionItemValue}>{connection.description ?? '-'}</Text>
        </div>
        <div className={classes.sectionItem}>
          <Text className={classes.sectionItemTitle}>{`Интегрируемая система`}</Text>
          <Text className={classes.sectionItemValue}>{connection.system?.name ?? '-'}</Text>
        </div>
        <div className={classes.sectionItem}>
          <Text className={classes.sectionItemTitle}>{`Тип источника`}</Text>
          <div className={classes.row}>
            {connection.source_type?.icon && (
              <img
                src={`data:image/svg+xml;base64,${connection.source_type.icon}`}
                className={classes.ico}
                alt="source type icon"
              />
            )}
            <Text className={classes.sectionItemValue}>{connection.source_type?.name ?? '-'}</Text>
          </div>
        </div>
        <div className={classes.sectionItem}>
          <Text className={classes.sectionItemTitle}>{`Тип подключения`}</Text>
          <Text className={classes.sectionItemValue}>{connection.connector_type?.name ?? '-'}</Text>
        </div>
        <div className={classes.sectionItem}>
          <Text className={classes.sectionItemTitle}>{`Версия источника`}</Text>
          <Text className={classes.sectionItemValue}>{connection.source_version ?? '-'}</Text>
        </div>
      </div>
      <CollapseBlock title={'Системное'} isOpen={true} isShortContainer={true} isShortChildrenContainer={true}>
        <div className={classes.usersGroup}>
          <div className={classes.sectionItem}>
            <Text className={classes.sectionItemTitle}>{`Создано ${
              connection._create_ts ? formatDateDDMMYYYYHHMM(new Date(connection._create_ts)) : '-'
            }`}</Text>
            {connection._create_user && <UserInfo user={connection._create_user} />}
          </div>
          <div className={classes.sectionItem}>
            <Text className={classes.sectionItemTitle}>{`Изменено ${
              connection._alter_ts ? formatDateDDMMYYYYHHMM(new Date(connection._alter_ts)) : '-'
            }`}</Text>
            {connection._alter_user && <UserInfo user={connection._alter_user} />}
          </div>
        </div>
      </CollapseBlock>
    </div>
  );
};

export default DisplayConnectionGeneral;
