import React, { useEffect } from 'react';
import classes from './ISPTable.module.css';
import { Table } from '../../../shared-front/Table/Table';
import { getShowColumns } from '../../../shared-front/Table/utils/getShowColumns';
import TableWrapper from '../../../Components/TableWrapper/TableWrapper';
import { INFO_SYSTEMS_TABLE_COLUMNS } from '../../../Templates/InfoSystemsTableColumns';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
  selectorInfoSystems,
  selectorInfoSystemsTableColumns,
  selectorIsLoadingInfoSystems,
  setInfoSystemsTableColumns,
} from '../../../store/infoSystemsSlice';
import { Loader } from '@consta/uikit/Loader';
import { getShortColumns } from '../../../shared-front/Table/utils/getShortColumns';
import { useColumnsStore } from '../../../hooks/useColumnsStore';
import { IspRightSideBarEnum } from '../InfoSystemsPage';
import { LeftSideBarHandleType } from '../../../shared-front/LeftSideBar/LeftSideBar';
import { formatDateDDMMYYYYHHMM } from '../../../utils/formatDateDDMMYYYYHHMM';
import TextWithMark from '../../../Components/TextWithMark/TextWithMark';
import BlueLinkOpenInNewTab from '../../../Components/BlueLinkOpenInNewTab/BlueLinkOpenInNewTab';

interface IISPTableProps {
  refSidebar: React.RefObject<LeftSideBarHandleType>;
  setRightSideBarMode: (mode: IspRightSideBarEnum | undefined) => void;
  activeInfoSystemUuid: string | undefined;
  setActiveInfoSystemUuid: (uuid: string | undefined) => void;
}

const columnsTemplate = INFO_SYSTEMS_TABLE_COLUMNS;

const ISPTable: React.FC<IISPTableProps> = ({
  refSidebar,
  setRightSideBarMode,
  activeInfoSystemUuid,
  setActiveInfoSystemUuid,
}) => {
  const dispatch = useAppDispatch();
  const infoSystems = useAppSelector(selectorInfoSystems);
  const columnsStore = useAppSelector(selectorInfoSystemsTableColumns);
  const isLoadingInfoSystems = useAppSelector(selectorIsLoadingInfoSystems);

  const columns = useColumnsStore({ columnsStore, columnsTemplate });

  useEffect(() => {
    dispatch(setInfoSystemsTableColumns(getShortColumns(columnsTemplate)));
  }, [dispatch]);

  const tableData = infoSystems
    ? infoSystems.data.map((infoSystem) => ({
        id: infoSystem._uuid,
        name: infoSystem.name ? infoSystem.name : '',
        description: infoSystem.description ? infoSystem.description : '',
        business_system: infoSystem.business_system?.name ? infoSystem.business_system.name : '',
        business_solution: infoSystem.business_solution?.name ? infoSystem.business_solution.name : '',
        technical_system: infoSystem.technical_system?.name ? infoSystem.technical_system.name : '',
        organization: infoSystem.organization?.name ? infoSystem.organization.name : '',
        en_name: infoSystem.en_name ? infoSystem.en_name : '',
        integration_dwh: (
          <TextWithMark
            isComplete={infoSystem.integration_dwh}
            textDone={'Интегрировано с хранилищем'}
            textNotDone={'Не интегрировано с хранилищем'}
          />
        ),
        scan_metadata: (
          <TextWithMark isComplete={infoSystem.scan_metadata} textDone={'Сканировано'} textNotDone={'Не сканировано'} />
        ),
        _create_ts: infoSystem._create_ts ? formatDateDDMMYYYYHHMM(new Date(infoSystem._create_ts)) : '',
        _create_user: infoSystem._create_user?.full_name ? infoSystem._create_user.full_name : '-',
        _alter_ts: infoSystem._alter_ts ? formatDateDDMMYYYYHHMM(new Date(infoSystem._alter_ts)) : '',
        _alter_user: infoSystem._alter_user?.full_name ? infoSystem._alter_user.full_name : '-',

        code: infoSystem.code ? infoSystem.code : '',
        full_name: infoSystem.full_name ? infoSystem.full_name : '',
        tech_name_edm: infoSystem.tech_name_edm ? infoSystem.tech_name_edm : '',
        block: infoSystem.block?.name ? infoSystem.block.name : '',
        link_edm: <BlueLinkOpenInNewTab linkUrl={infoSystem.link_edm} linkTitle={'Смотреть в EDM'} />,
      }))
    : [];

  const onRowClick = (originalRow: { [key: string]: unknown }) => {
    setActiveInfoSystemUuid(originalRow.id as string);
    setRightSideBarMode(IspRightSideBarEnum.DisplaySystem);
    const isSidebarOpen = refSidebar.current?.getIsSidebarOpen();
    if (!isSidebarOpen) {
      refSidebar.current?.openSidebar();
    }
  };

  return (
    <div className={classes.container}>
      {isLoadingInfoSystems ? (
        <Loader />
      ) : (
        <TableWrapper columns={columns} height={'100%'}>
          <Table
            data={tableData}
            columns={getShowColumns(columns)}
            onRowClick={onRowClick}
            isColumnsResizable={true}
            isRowHover={true}
            highlightedRowId={activeInfoSystemUuid}
          />
        </TableWrapper>
      )}
    </div>
  );
};

export default ISPTable;
