import React from 'react';

export const setTableSearchStyle = (value?: string | null | undefined) => {
  if (value) {
    const tableSearchStyleValue = value
      .replace('<b>', `<span style="background-color:#DEE4E8;">`)
      .replace('</b>', '</span>');
    return <div dangerouslySetInnerHTML={{ __html: tableSearchStyleValue }} />;
  }
  return null;
};
