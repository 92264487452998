import React from 'react';

import CollapseBlock from '../../../../../../../Components/CollapseBlock/CollapseBlock';
import CreateConnectionItemSettings from '../CreateConnectionItemSettings/CreateConnectionItemSettings';
import { Button } from '@consta/uikit/Button';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/hooks';
import {
  selectorActiveSourceType,
  selectorConnectionGeneralSettings,
  selectorConnectionInstance,
  selectorConnectionName,
  setConnectionGeneralSettings,
  setConnectionGeneralSettingsToAllAssets,
} from '../../../../../../../store/createNewConnectionSlice';
import { ConnectionSettingsItemType } from '../../../../../../../types/types';
import { maxPreviewValue, minPreviewValue } from '../../../../../../../utils/constants';
import { pushSnackBarItemConnections } from '../../../../../../../store/connectionsSlice';

interface ICreateConnectionGeneralSettingsProps {}

const CreateConnectionGeneralSettings: React.FC<ICreateConnectionGeneralSettingsProps> = () => {
  const dispatch = useAppDispatch();
  const activeSourceType = useAppSelector(selectorActiveSourceType);
  const connectionGeneralSettings = useAppSelector(selectorConnectionGeneralSettings);
  const oracleConnectionInstanceName =
    useAppSelector(selectorConnectionInstance)?.toString() ?? 'oracleConnectionInstance';
  const odataConnectionInstanceName = useAppSelector(selectorConnectionName)?.toString() ?? 'odataConnectionURL';

  const isOdata = activeSourceType?.card_view === 'odata';
  const isOracle = activeSourceType?.card_view === 'oracle';
  const isDb = !isOdata && !isOracle;

  const setGeneralConnectionSettings = (connectionSettings: ConnectionSettingsItemType[] | undefined) => {
    dispatch(setConnectionGeneralSettings(connectionSettings));
  };

  const onClickApplyToAll = () => {
    const limitsForPreviewValue = connectionGeneralSettings?.find(
      (setting) => setting.key === 'limits_for_preview'
    )?.value;
    if (limitsForPreviewValue) {
      const valueInRange = limitsForPreviewValue >= minPreviewValue && limitsForPreviewValue <= maxPreviewValue;
      if (valueInRange) {
        dispatch(setConnectionGeneralSettingsToAllAssets());
        dispatch(
          pushSnackBarItemConnections({
            key: Date.now(),
            message: 'Общая настройка подключения\nприменена ко всем БД',
            status: 'success',
            autoClose: true,
          })
        );
      } else {
        dispatch(
          pushSnackBarItemConnections({
            key: Date.now(),
            message: 'Общая настройка подключения\nНеверный диапазон предпросмотра',
            status: 'alert',
            autoClose: true,
          })
        );
      }
    } else {
      dispatch(setConnectionGeneralSettingsToAllAssets());
      dispatch(
        pushSnackBarItemConnections({
          key: Date.now(),
          message: 'Общая настройка подключения\nприменена ко всем БД',
          status: 'success',
          autoClose: true,
        })
      );
    }
  };

  return isDb ? (
    <CollapseBlock
      title={'Общая настройка подключения'}
      isOpen={true}
      isShortContainer={true}
      isShortChildrenContainer={true}
      informer={`Общая форма настройки подключения\nпозволяет после выбора необходимых\nпараметров, применить эти настройки\nко всем выбранным базам данных с\nпомощью кнопки "Применить ко всем\nБД"`}
    >
      <CreateConnectionItemSettings
        connectionSettings={connectionGeneralSettings}
        setConnectionSettings={setGeneralConnectionSettings}
      />

      <Button
        size={'s'}
        label={'Применить ко всем БД'}
        style={{ width: '100%', marginTop: '24px' }}
        view={'ghost'}
        onClick={onClickApplyToAll}
      />
    </CollapseBlock>
  ) : (
    <CollapseBlock
      title={isOracle ? oracleConnectionInstanceName : odataConnectionInstanceName}
      isOpen={true}
      isShortContainer={true}
      isShortChildrenContainer={true}
    >
      <CreateConnectionItemSettings
        connectionSettings={connectionGeneralSettings}
        setConnectionSettings={setGeneralConnectionSettings}
      />
    </CollapseBlock>
  );
};

export default CreateConnectionGeneralSettings;
