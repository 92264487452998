import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../../../../hooks/hooks';
import {
  selectorEditConnection,
  setEditConnectionSettings,
  setEditConnectionSettingsToAllAssets,
} from '../../../../../../../../store/editConnectionSlice';
import { ConnectionSettingsItemType } from '../../../../../../../../types/types';
import { maxPreviewValue, minPreviewValue } from '../../../../../../../../utils/constants';
import { pushSnackBarItemConnections } from '../../../../../../../../store/connectionsSlice';
import CollapseBlock from '../../../../../../../../Components/CollapseBlock/CollapseBlock';
import CreateConnectionItemSettings from '../../../../../CPRightSideBarCreateConnection/CreateProgressStepSelector/CreateConnectionStepFour/CreateConnectionItemSettings/CreateConnectionItemSettings';
import { Button } from '@consta/uikit/Button';

interface IEditConnectionGeneralSettingsProps {}

const EditConnectionGeneralSettings: React.FC<IEditConnectionGeneralSettingsProps> = () => {
  const dispatch = useAppDispatch();
  const editConnection = useAppSelector(selectorEditConnection);

  const setGeneralConnectionSettings = (connectionSettings: ConnectionSettingsItemType[] | undefined) => {
    dispatch(setEditConnectionSettings(connectionSettings));
  };

  const onClickApplyToAll = () => {
    if (editConnection?.settings) {
      const limitsForPreviewValue = editConnection.settings.find(
        (setting) => setting.key === 'limits_for_preview'
      )?.value;
      if (limitsForPreviewValue) {
        const valueInRange = limitsForPreviewValue >= minPreviewValue && limitsForPreviewValue <= maxPreviewValue;
        if (valueInRange) {
          dispatch(setEditConnectionSettingsToAllAssets());
          dispatch(
            pushSnackBarItemConnections({
              key: Date.now(),
              message: 'Общая настройка подключения\nприменена ко всем БД',
              status: 'success',
              autoClose: true,
            })
          );
        } else {
          dispatch(
            pushSnackBarItemConnections({
              key: Date.now(),
              message: 'Общая настройка подключения\nНеверный диапазон предпросмотра',
              status: 'alert',
              autoClose: true,
            })
          );
        }
      } else {
        dispatch(setEditConnectionSettingsToAllAssets());
        dispatch(
          pushSnackBarItemConnections({
            key: Date.now(),
            message: 'Общая настройка подключения\nприменена ко всем БД',
            status: 'success',
            autoClose: true,
          })
        );
      }
    }
  };

  const isOdata = editConnection?.source_type?.card_view === 'odata';
  const isOracle = editConnection?.source_type?.card_view === 'oracle';
  const isDb = !isOdata && !isOracle;

  return isDb ? (
    <CollapseBlock
      title={'Общая настройка подключения'}
      isOpen={true}
      isShortContainer={true}
      isShortChildrenContainer={true}
      informer={`Общая форма настройки подключения\nпозволяет после выбора необходимых\nпараметров, применить эти настройки\nко всем выбранным базам данных с\nпомощью кнопки "Применить ко всем\nБД"`}
    >
      <CreateConnectionItemSettings
        connectionSettings={editConnection?.settings}
        setConnectionSettings={setGeneralConnectionSettings}
      />
      <Button
        size={'s'}
        label={'Применить ко всем БД'}
        style={{ width: '100%', marginTop: '24px' }}
        view={'ghost'}
        onClick={onClickApplyToAll}
      />
    </CollapseBlock>
  ) : null;
};

export default EditConnectionGeneralSettings;
