import { ColumnGenericType } from '../types/types';
import { createTableColumn } from '../shared-front/Table/utils/createTableColumn';
import { ReactNode } from 'react';

export type InfoSystemsTableDataType = {
  code?: string | null;
  name?: string | null;
  full_name?: string | null;
  description?: string | null;
  business_system?: string | null;
  business_solution?: string | null;
  technical_system?: string | null;
  en_name?: string | null;
  integration_dwh?: ReactNode;
  scan_metadata?: ReactNode;
  _create_ts?: string | null;
  _create_user?: string | null;
  _alter_ts?: string | null;
  _alter_user?: string | null;
  link_edm?: ReactNode;
  block?: string | null;
};

export const INFO_SYSTEMS_TABLE_COLUMNS: ColumnGenericType[] = [
  createTableColumn<InfoSystemsTableDataType>({
    accessorKey: 'code',
    title: 'Код',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<InfoSystemsTableDataType>({
    accessorKey: 'name',
    title: 'Наименование',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<InfoSystemsTableDataType>({
    accessorKey: 'full_name',
    title: 'Полное наименование',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<InfoSystemsTableDataType>({
    accessorKey: 'en_name',
    title: 'Техническое наименование',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<InfoSystemsTableDataType>({
    accessorKey: 'tech_name_edm',
    title: 'Краткое техническое наименование',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<InfoSystemsTableDataType>({
    accessorKey: 'description',
    title: 'Описание',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<InfoSystemsTableDataType>({
    accessorKey: 'business_system',
    title: 'Бизнес-система',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<InfoSystemsTableDataType>({
    accessorKey: 'business_solution',
    title: 'Бизнес-решение',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<InfoSystemsTableDataType>({
    accessorKey: 'technical_system',
    title: 'Техническая система',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),

  createTableColumn<InfoSystemsTableDataType>({
    accessorKey: 'organization',
    title: 'Организация',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<InfoSystemsTableDataType>({
    accessorKey: 'block',
    title: 'Блок',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<InfoSystemsTableDataType>({
    accessorKey: 'link_edm',
    title: 'Открыть в EDM',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<InfoSystemsTableDataType>({
    accessorKey: 'integration_dwh',
    title: 'Интегрирация с хранилищем Дата-платформы',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<InfoSystemsTableDataType>({
    accessorKey: 'scan_metadata',
    title: 'Сканирование метаданных',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<InfoSystemsTableDataType>({
    accessorKey: '_create_ts',
    title: 'Создано',
    textLinesNumber: 1,
    isShow: false,
    size: 300,
  }),
  createTableColumn<InfoSystemsTableDataType>({
    accessorKey: '_create_user',
    title: 'Создал',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<InfoSystemsTableDataType>({
    accessorKey: '_alter_ts',
    title: 'Изменено',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<InfoSystemsTableDataType>({
    accessorKey: '_alter_user',
    title: 'Изменил',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
];
