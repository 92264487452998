import React, { useState } from 'react';

import EditConnectionGeneralSettings from './EditConnectionGeneralSettings; /EditConnectionGeneralSettings/EditConnectionGeneralSettings';
import classes from './EditConnectionAdditional.module.css';
import ConnectionSearchRow from '../../../../../../Components/ConnectionSearchRow/ConnectionSearchRow';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import { selectorEditConnection, setEditConnectionAssetSettings } from '../../../../../../store/editConnectionSlice';
import ConnectionAssetsList from '../../../../../../Components/ConnectionAssetsList/ConnectionAssetsList';
import { CheckConnectionAssetType } from '../../../../../../api/serverResponse';
import { ConnectionSettingsItemType } from '../../../../../../types/types';

interface IEditConnectionAdditionalProps {}

const EditConnectionAdditional: React.FC<IEditConnectionAdditionalProps> = () => {
  const dispatch = useAppDispatch();
  const editConnection = useAppSelector(selectorEditConnection);

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const onChangeEditConnectionAsset = ({
    connectionAsset,
    assetSettings,
  }: {
    connectionAsset: CheckConnectionAssetType;
    assetSettings: ConnectionSettingsItemType[] | undefined;
  }) => {
    dispatch(setEditConnectionAssetSettings({ connectionAsset, assetSettings }));
  };

  return (
    <div className={classes.wrapper}>
      <ConnectionSearchRow
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        cardView={editConnection?.source_type?.card_view}
      />
      <div className={classes.container}>
        {!searchValue && <EditConnectionGeneralSettings />}
        <ConnectionAssetsList
          searchValue={searchValue}
          assets={editConnection?.assets as CheckConnectionAssetType[] | undefined}
          onChangeConnectionAsset={onChangeEditConnectionAsset}
        />
      </div>
    </div>
  );
};

export default EditConnectionAdditional;
