import React, { useCallback } from 'react';
import { formatDateDDMMYYYYHHMM } from '../utils/formatDateDDMMYYYYHHMM';
import { useAppSelector } from './hooks';
import { selectorLaunchHistory } from '../store/launchHistorySlice';
import { LaunchHistoryTableDataType } from '../Templates/launchHistoryTableColumns';
import LaunchHistoryStatusBadge from '../Pages/ConnectionsPage/CPContentSelector/LaunchHistoryContent/LaunchHistoryContentContainer/LaunchHistoryTable/LaunchHistoryStatusBadge/LaunchHistoryStatusBadge';
import LaunchHistoryButtonLink from '../Pages/ConnectionsPage/CPContentSelector/LaunchHistoryContent/LaunchHistoryContentContainer/LaunchHistoryTable/LaunchHistoryButtonLink/LaunchHistoryButtonLink';

export const useGetLaunchHistoryTableData = () => {
  const launchHistoryData = useAppSelector(selectorLaunchHistory)?.data;

  return useCallback(() => {
    const tableData: LaunchHistoryTableDataType[] = [];
    if (!!launchHistoryData?.length) {
      launchHistoryData.forEach((launchHistoryItem, ind) => {
        tableData.push({
          id: ind.toString(),
          uuid: `${launchHistoryItem._uuid}`,
          connectionName: launchHistoryItem.name ? launchHistoryItem.name : '',
          typeOfConnection: launchHistoryItem.type ? launchHistoryItem.type : '',
          objects: launchHistoryItem.objects ? launchHistoryItem.objects : '',
          status: <LaunchHistoryStatusBadge status={launchHistoryItem.status} />,
          airflowLink: <LaunchHistoryButtonLink airflowLink={launchHistoryItem.airflow} />,
          userName: launchHistoryItem.user ? launchHistoryItem.user : '',
          date: launchHistoryItem.date ? formatDateDDMMYYYYHHMM(new Date(launchHistoryItem.date)) : '',
        });
      });
    }
    return tableData;
  }, [launchHistoryData]);
};
