import React from 'react';

import classes from './AdditionalSetting.module.css';
import { Text } from '@consta/uikit/Text';
import { ConnectionSettingsItemType } from '../../../../../../../../types/types';
import { Checkbox } from '@consta/uikit/Checkbox';

interface IAdditionalSettingProps {
  connectionSettings: ConnectionSettingsItemType[] | undefined;
  setConnectionSettings?: (connectionSettings: ConnectionSettingsItemType[] | undefined) => void;
  isDisplayMode?: boolean;
}

const AdditionalSetting: React.FC<IAdditionalSettingProps> = ({
  connectionSettings,
  setConnectionSettings,
  isDisplayMode,
}) => {
  const additionalSettingsItem = connectionSettings?.find((setting) => setting.key === 'additional_settings');
  const additionalSettingsValue = additionalSettingsItem?.value as ConnectionSettingsItemType[];
  const hasAdditionalSettings = !!additionalSettingsValue?.length;

  const onChangeAdditionalSettingsItem = (additionalSettingItem: ConnectionSettingsItemType) => {
    if (connectionSettings && setConnectionSettings) {
      setConnectionSettings(
        connectionSettings.map((setting) =>
          setting.key === 'additional_settings'
            ? {
                ...setting,
                value: (setting.value as ConnectionSettingsItemType[]).map((item) =>
                  item._uuid === additionalSettingItem._uuid ? { ...item, isChecked: !item.isChecked } : item
                ),
              }
            : setting
        )
      );
    }
  };

  return isDisplayMode || hasAdditionalSettings ? (
    <div className={classes.container}>
      <div className={classes.additionalSettingsGroup}>
        <Text className={classes.groupTitle}>{'Дополнительные типы объектов'}</Text>
        <div className={classes.additionalSettingsList}>
          {additionalSettingsValue ? (
            additionalSettingsValue.map((additionalSettingsItem, ind) => (
              <Checkbox
                key={ind}
                size={'m'}
                checked={additionalSettingsItem.isChecked}
                label={additionalSettingsItem.value?.toString()}
                onChange={() => onChangeAdditionalSettingsItem(additionalSettingsItem)}
                disabled={isDisplayMode}
              />
            ))
          ) : (
            <Text className={classes.limitsText}>{`нет`}</Text>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default AdditionalSetting;
