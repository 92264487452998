import React from 'react';

import classes from './SendToEdmSettings.module.css';
import { Text } from '@consta/uikit/Text';
import { Checkbox } from '@consta/uikit/Checkbox';
import { ConnectionSettingsItemType } from '../../../../../../../../types/types';

interface ISendToEdmSettingsProps {
  connectionSettings: ConnectionSettingsItemType[] | undefined;
  setConnectionSettings?: (connectionSettings: ConnectionSettingsItemType[] | undefined) => void;
  isDisplayMode?: boolean;
}

const SendToEdmSettings: React.FC<ISendToEdmSettingsProps> = ({
  connectionSettings,
  setConnectionSettings,
  isDisplayMode,
}) => {
  const hasSendToEdmSettings = connectionSettings?.find((setting) => setting.key === 'is_send_to_edm');
  const isSendToEdm = !!connectionSettings?.find((setting) => setting.key === 'is_send_to_edm')?.value;

  const onChangeIsSendToEdm = () => {
    if (connectionSettings && setConnectionSettings) {
      setConnectionSettings(
        connectionSettings.map((setting) =>
          setting.key === 'is_send_to_edm' ? { ...setting, value: !setting.value } : setting
        )
      );
    }
  };

  return isDisplayMode || hasSendToEdmSettings ? (
    <div className={classes.container}>
      <div className={classes.additionalSettingsGroup}>
        <Text className={classes.groupTitle}>{'EDM'}</Text>
        <Checkbox
          size={'m'}
          checked={isSendToEdm}
          label={'Передавать метаданные в EDM'}
          onChange={onChangeIsSendToEdm}
          disabled={isDisplayMode}
        />
      </div>
    </div>
  ) : null;
};

export default SendToEdmSettings;
