import React from 'react';

import classes from './TableContainerDataPreview.module.css';
import { useAppSelector } from '../../../hooks/hooks';
import { selectorCatalogsIsLoadingPreviewData } from '../../../store/catalogSlice';
import { Bookmark1CObject, BookmarkType } from '../../../types/types';
import { createTableColumn } from '../../../shared-front/Table/utils/createTableColumn';
import { Loader } from '@consta/uikit/Loader';
import { Table } from '../../../shared-front/Table/Table';
import { Text } from '@consta/uikit/Text';

interface ITableContainerDataPreviewProps {
  bookmark: BookmarkType;
}

export type TableDataPreviewType = {
  [key: string]: string;
};

export const getDataPreviewColumns = (columns: string[]) => {
  return columns.map((column) => {
    return createTableColumn<TableDataPreviewType>({
      accessorKey: column,
      title: column,
      size: 300,
      isShow: true,
    });
  });
};

const TableContainerDataPreview: React.FC<ITableContainerDataPreviewProps> = ({ bookmark }) => {
  const isLoading = useAppSelector(selectorCatalogsIsLoadingPreviewData);
  const data = bookmark.data as Bookmark1CObject | undefined;

  const tableDataPreviewColumns = getDataPreviewColumns(data?.previewData?.data?.columns_meta || []);

  const tableData =
    data?.previewData?.data?.columns?.map((preview) => {
      const entries = Object.entries(preview);
      const newObj: { [key: string]: string } = {};
      entries.forEach((entry) => {
        newObj[entry[0].toString()] = entry[1]?.toString();
        return newObj;
      });
      return newObj;
    }) || [];

  return (
    <div className={classes.container}>
      <div className={classes.tableContainer}>
        {isLoading ? (
          <Loader />
        ) : !!tableData.length ? (
          <div className={classes.tableWrapper}>
            <Table data={tableData} columns={tableDataPreviewColumns} isColumnsResizable={true} />
          </div>
        ) : (
          <Text className={classes.nothingFound}>{`Ничего не найдено`}</Text>
        )}
      </div>
    </div>
  );
};

export default TableContainerDataPreview;
