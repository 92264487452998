import React, { useCallback } from 'react';
import { ConnectionsTableDataType } from '../Templates/connectionsTableColumns';
import ConnectionsTableObjects from '../Pages/ConnectionsPage/CPContentSelector/ConnectionsContent/ConnectionsContentContainer/ConnectionsTable/ConnectionsTableObjects/ConnectionsTableObjects';
import { ProgressSpin } from '@consta/uikit/ProgressSpin';
import CustomBlueTextButton from '../Components/CustomBlueTextButton/CustomBlueTextButton';
import {
  postConnectionAssetRunPreviewThunk,
  postConnectionAssetRunScannerThunk,
  postConnectionRunPreviewThunk,
  postConnectionRunScannerThunk,
  selectorConnections,
  selectorConnectionsTableHoveredRowUuid,
  selectorConnectionsUuidWithOpenedAssets,
  selectorRunningPreviewsUuids,
  selectorRunningScannersUuids,
} from '../store/connectionsSlice';
import { formatDateDDMMYYYYHHMM } from '../utils/formatDateDDMMYYYYHHMM';
import { useAppDispatch, useAppSelector } from './hooks';

export const useGetConnectionsTableData = () => {
  const dispatch = useAppDispatch();
  const connections = useAppSelector(selectorConnections);
  const runningScannersUuids = useAppSelector(selectorRunningScannersUuids);
  const runningPreviewsUuids = useAppSelector(selectorRunningPreviewsUuids);
  const connectionsUuidWithOpenedAssets = useAppSelector(selectorConnectionsUuidWithOpenedAssets);
  const tableHoveredRowUuid = useAppSelector(selectorConnectionsTableHoveredRowUuid);

  return useCallback(() => {
    const tableData: (ConnectionsTableDataType & {
      rowId?: string;
    })[] = [];
    if (!!connections?.data.length) {
      connections.data.forEach((connection, ind) => {
        tableData.push({
          id: ind.toString(),
          rowId: `${connection._uuid}`,
          systemName: connection.system ? (connection.system.name ? connection.system.name : '') : '',
          connectionName: connection.name ? connection.name : '',
          objects: <ConnectionsTableObjects connection={connection} hoveredConnectionUuid={tableHoveredRowUuid} />,
          buttonScan: runningScannersUuids.includes(connection._uuid) ? (
            <ProgressSpin style={{ marginLeft: '8px' }} />
          ) : connection.scanner_id ? (
            <CustomBlueTextButton
              title={'Запуск'}
              onClick={() => dispatch(postConnectionRunScannerThunk(connection._uuid))}
            />
          ) : (
            <div style={{ marginLeft: '12px' }}>{'-'}</div>
          ),
          buttonPreview: runningPreviewsUuids.includes(connection._uuid) ? (
            <ProgressSpin style={{ marginLeft: '8px' }} />
          ) : connection.preview_scanner_id ? (
            <CustomBlueTextButton
              title={'Запуск'}
              onClick={() => dispatch(postConnectionRunPreviewThunk(connection._uuid))}
            />
          ) : (
            <div style={{ marginLeft: '12px' }}>{'-'}</div>
          ),
          sendToEdm:
            connection.assets?.length === 1 && connection.assets?.[0]
              ? connection.assets[0].is_send_to_edm
                ? 'Да'
                : 'Нет'
              : '',
          description: connection.description ? connection.description : '',
          typeOfConnection: connection.connector_type ? connection.connector_type.name : '',
          typeOfSource: connection.source_type ? connection.source_type.name : '',
          _create_ts: connection._create_ts ? formatDateDDMMYYYYHHMM(new Date(connection._create_ts)) : '',
          _create_user: connection._create_user?.full_name ? connection._create_user.full_name : '-',
          _alter_ts: connection._alter_ts ? formatDateDDMMYYYYHHMM(new Date(connection._alter_ts)) : '',
          _alter_user: connection._alter_user?.full_name ? connection._alter_user.full_name : '-',
        });
        if (
          connection.assets?.length &&
          connection.assets.length > 1 &&
          connectionsUuidWithOpenedAssets.includes(connection._uuid) &&
          !!connection.assets?.length
        ) {
          connection.assets.forEach((asset) => {
            tableData.push({
              rowId: `${asset._uuid}_innerRow`,
              systemName: '',
              connectionName: '',
              objects: <div style={{ marginLeft: '48px' }}>{asset.name}</div>,
              buttonScan: runningScannersUuids.includes(asset._uuid) ? (
                <ProgressSpin style={{ marginLeft: '8px', height: '26px' }} />
              ) : asset.scanner_id ? (
                <CustomBlueTextButton
                  title={'Запуск'}
                  onClick={() =>
                    dispatch(
                      postConnectionAssetRunScannerThunk({ connectionUuid: connection._uuid, assetUuid: asset._uuid })
                    )
                  }
                />
              ) : (
                <div style={{ marginLeft: '12px' }}>{'-'}</div>
              ),
              buttonPreview: runningPreviewsUuids.includes(asset._uuid) ? (
                <ProgressSpin style={{ marginLeft: '8px', height: '26px' }} />
              ) : asset.preview_scanner_id ? (
                <CustomBlueTextButton
                  title={'Запуск'}
                  onClick={() =>
                    dispatch(
                      postConnectionAssetRunPreviewThunk({ connectionUuid: connection._uuid, assetUuid: asset._uuid })
                    )
                  }
                />
              ) : (
                <div style={{ marginLeft: '12px' }}>{'-'}</div>
              ),
              sendToEdm: asset.is_send_to_edm ? 'Да' : 'Нет',
              description: '',
              typeOfConnection: '',
              typeOfSource: '',
              _create_ts: '',
              _create_user: '',
              _alter_ts: '',
              _alter_user: '',
            });
          });
        }
      });
    }
    return tableData;
  }, [
    dispatch,
    connections?.data,
    connectionsUuidWithOpenedAssets,
    runningScannersUuids,
    runningPreviewsUuids,
    tableHoveredRowUuid,
  ]);
};
