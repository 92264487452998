import React from 'react';

import classes from './CardDbContent.module.css';
import InfoDbRow from './InfoDbRow/InfoDbRow';
import { BookmarkType } from '../../../../types/types';
import CardTabsRow, { CardTabType } from '../../../../Components/CardTabsRow/CardTabsRow';
import ContentDbByTabs from './ContentDbByTabs/ContentDbByTabs';
import { useActiveTab } from '../../../../hooks/useActiveTab';

interface ICardRdbContentProps {
  bookmark: BookmarkType;
}

export const TABS_RDB: CardTabType[] = [
  { id: 0, label: 'Общая информация' },
  { id: 1, label: 'Объекты' },
];

const CardDbContent: React.FC<ICardRdbContentProps> = ({ bookmark }) => {
  const [activeTab, setActiveTab] = useActiveTab({ bookmark, tabs: TABS_RDB });

  return (
    <div className={classes.container}>
      <InfoDbRow bookmark={bookmark} />
      <CardTabsRow tabs={TABS_RDB} activeTab={activeTab} setActiveTab={setActiveTab} />
      <ContentDbByTabs activeTab={activeTab} bookmark={bookmark} />
    </div>
  );
};

export default CardDbContent;
