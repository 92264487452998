import { DataFilterType } from '../Components/FilterData/FilterData';
import { formatDateYYYYMMDD } from './formatDateYYYYMMDD';

export const getQueryDataFilters = (dataFilters: DataFilterType[]) => {
  const queries: string[] = [];
  dataFilters.forEach((dataFilter) => {
    if (dataFilter.dataRange[0] || dataFilter.dataRange[1]) {
      queries.push(
        `filter[${dataFilter.name}]=${formatDateYYYYMMDD(dataFilter.dataRange[0]!)}${
          dataFilter.dataRange[1] ? `:${formatDateYYYYMMDD(dataFilter.dataRange[1]!)}` : ''
        }`
      );
    }
  });
  return queries.join('&');
};
