import React from 'react';

import classes from './CreateConnectionStepFour.module.css';
import ConnectionSearchRow from '../../../../../../Components/ConnectionSearchRow/ConnectionSearchRow';
import ConnectionAssetsList from '../../../../../../Components/ConnectionAssetsList/ConnectionAssetsList';
import CreateConnectionGeneralSettings from './CreateConnectionGeneralSettings/CreateConnectionGeneralSettings';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import {
  selectorActiveSourceType,
  selectorConnectionSearchValue,
  selectorNewConnectionSelectedAssets,
  setConnectionSearchValue,
  setNewConnectionAssetSettings,
} from '../../../../../../store/createNewConnectionSlice';
import { CheckConnectionAssetType } from '../../../../../../api/serverResponse';
import { ConnectionSettingsItemType } from '../../../../../../types/types';

interface ICreateConnectionStepFourProps {}

const CreateConnectionStepFour: React.FC<ICreateConnectionStepFourProps> = () => {
  const dispatch = useAppDispatch();
  const searchValue = useAppSelector(selectorConnectionSearchValue);
  const activeSourceType = useAppSelector(selectorActiveSourceType);
  const newConnectionSelectedAssets = useAppSelector(selectorNewConnectionSelectedAssets);

  const setSearchValue = (value: string) => {
    dispatch(setConnectionSearchValue(value));
  };

  const onChangeNewConnectionAsset = ({
    connectionAsset,
    assetSettings,
  }: {
    connectionAsset: CheckConnectionAssetType;
    assetSettings: ConnectionSettingsItemType[] | undefined;
  }) => {
    dispatch(setNewConnectionAssetSettings({ connectionAsset, assetSettings }));
  };

  return (
    <div className={classes.wrapper}>
      <ConnectionSearchRow
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        cardView={activeSourceType?.card_view}
      />
      <div className={classes.container}>
        {!searchValue && <CreateConnectionGeneralSettings />}
        <ConnectionAssetsList
          searchValue={searchValue}
          assets={newConnectionSelectedAssets}
          cardView={activeSourceType?.card_view}
          onChangeConnectionAsset={onChangeNewConnectionAsset}
        />
      </div>
    </div>
  );
};

export default CreateConnectionStepFour;
