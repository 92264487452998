import React from 'react';

import classes from './RightSideBarFormSelector.module.css';
import { Combobox } from '@consta/uikit/Combobox';
import { ItemComboboxType } from '../../types/types';

interface IRightSideBarFormSelectorProps {
  value: ItemComboboxType | null | undefined;
  items: ItemComboboxType[] | undefined;
  onChange: (value: ItemComboboxType | null) => void;
  required?: boolean;
  isRequired?: boolean;
  label?: string;
  placeholder?: string;
  isLoading?: boolean | undefined;
  onLoadItems?: () => void;
}

const RightSideBarFormSelector: React.FC<IRightSideBarFormSelectorProps> = ({
  value,
  items,
  onChange,
  required,
  isRequired,
  label,
  placeholder,
  isLoading,
  onLoadItems,
}) => {
  const onChangeHandler = ({ value }: { value: ItemComboboxType | null }) => {
    onChange && onChange(value);
  };

  const loadItemsHandler = () => {
    if (!isLoading && !items && onLoadItems) {
      onLoadItems();
    }
  };

  return (
    <div className={classes.container}>
      <Combobox
        label={label}
        items={items ? items : []}
        value={value}
        required={required}
        placeholder={placeholder}
        status={required && isRequired ? 'alert' : undefined}
        caption={required && isRequired ? 'Обязательное к заполнению поле' : undefined}
        onChange={onChangeHandler}
        style={{ zIndex: 999 }}
        isLoading={isLoading}
        onClick={loadItemsHandler}
      />
    </div>
  );
};

export default RightSideBarFormSelector;
