import React from 'react';

import classes from './LimitsForObjectsSettings.module.css';
import { ConnectionSettingsItemType } from '../../../../../../../../types/types';
import { Text } from '@consta/uikit/Text';
import { Radio } from '@consta/uikit/Radio';
import CustomHoverToolTip from '../../../../../../../../Components/CustomHoverToolTip/CustomHoverToolTip';
import iconInfoCircle from '../../../../../../../../assets/CatalogPage/iconInfoCircle.svg';
import { TextField } from '@consta/uikit/TextField';

interface ILimitsForObjectsSettingsProps {
  connectionSettings: ConnectionSettingsItemType[] | undefined;
  setConnectionSettings?: (connectionSettings: ConnectionSettingsItemType[] | undefined) => void;
  isDisplayMode?: boolean;
}

const LimitsForObjectsSettings: React.FC<ILimitsForObjectsSettingsProps> = ({
  connectionSettings,
  setConnectionSettings,
  isDisplayMode,
}) => {
  const hasLimitsForObject = connectionSettings?.find((setting) => setting.key === 'limits_for_object');
  const limitsForObjectValue = connectionSettings?.find((setting) => setting.key === 'limits_for_object')?.value;
  const hasTextLimitsForObject = typeof limitsForObjectValue === 'string';
  const resetLimitsForObjects = () => {
    if (connectionSettings && setConnectionSettings) {
      setConnectionSettings(
        connectionSettings.map((setting) =>
          setting.key === 'limits_for_object' ? { ...setting, value: null } : setting
        )
      );
    }
  };
  const setEmptyLimitsForObjects = () => {
    if (connectionSettings && setConnectionSettings) {
      setConnectionSettings(
        connectionSettings.map((setting) => (setting.key === 'limits_for_object' ? { ...setting, value: '' } : setting))
      );
    }
  };
  const onChangeLimitsForObjects = ({ value }: { value: string | null }) => {
    if (connectionSettings && setConnectionSettings) {
      setConnectionSettings(
        connectionSettings.map((setting) =>
          setting.key === 'limits_for_object' ? { ...setting, value: value ? value : '' } : setting
        )
      );
    }
  };

  return isDisplayMode || hasLimitsForObject ? (
    <div className={classes.container}>
      <div className={classes.radioButtonGroup}>
        <Text className={classes.groupTitle}>{'Объекты'}</Text>
        <div className={classes.buttonsRow}>
          <Radio
            label={'Все'}
            checked={!hasTextLimitsForObject}
            onChange={resetLimitsForObjects}
            disabled={isDisplayMode && hasTextLimitsForObject}
          />
          <Radio
            label={'С ограничениями'}
            checked={hasTextLimitsForObject}
            onChange={setEmptyLimitsForObjects}
            disabled={isDisplayMode && !hasTextLimitsForObject}
          />
          {!isDisplayMode && (
            <div className={classes.toolTip}>
              <CustomHoverToolTip
                title={`Для перечисления обьектов используется\nзапятая в качестве разделителя.\nДоступно использование маски % и\nоператора NOT.`}
                zIndex={999}
              >
                <img src={iconInfoCircle} className={classes.ico} alt={'infoBtn'} />
              </CustomHoverToolTip>
            </div>
          )}
        </div>
        {hasTextLimitsForObject ? (
          isDisplayMode ? (
            <Text className={classes.limitsText}>{limitsForObjectValue as string}</Text>
          ) : (
            <TextField
              id={'limitsForObjects'}
              value={limitsForObjectValue}
              placeholder={'Укажите ограничения'}
              onChange={onChangeLimitsForObjects}
            />
          )
        ) : null}
      </div>
    </div>
  ) : null;
};

export default LimitsForObjectsSettings;
