import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { serverApi } from '../api/serverApi';
import { getRejectedValue } from './rejectedValueHelper';
import { InfoSystemsServerResponseType } from '../api/serverResponse';
import {
  ColumnGenericType,
  InfoSystemType,
  OrganizationAndSubSystemType,
  OrganizationsAndSubSystemsType,
  StoreSnackBarItemType,
} from '../types/types';

type AppSliceType = {
  infoSystems: InfoSystemsServerResponseType | undefined;
  tableColumns: ColumnGenericType[];
  searchValueInfoSystems: string;
  organizationsAndSubSystems: OrganizationsAndSubSystemsType | undefined;
  snackBarItems: StoreSnackBarItemType[];
  isLoadingInfoSystems: boolean;
  isErrorInfoSystems: string | undefined;
  isLoadingSubSystems: boolean;
  isErrorSubSystems: string | undefined;
};

const initialState: AppSliceType = {
  infoSystems: undefined,
  tableColumns: [],
  searchValueInfoSystems: '',
  organizationsAndSubSystems: undefined,
  snackBarItems: [],
  isLoadingInfoSystems: false,
  isErrorInfoSystems: undefined,
  isLoadingSubSystems: false,
  isErrorSubSystems: undefined,
};

export const getInfoSystemsThunk = createAsyncThunk<InfoSystemsServerResponseType, string, { rejectValue: string }>(
  'app/getInfoSystemsThunk',
  async (requestQuery, { rejectWithValue }) => {
    try {
      return await serverApi.getInfoSystems(requestQuery);
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения данных Информационных Систем', e));
    }
  }
);

export const getOrganizationsAndSubSystemsForInfoSystemsThunk = createAsyncThunk<
  OrganizationsAndSubSystemsType,
  undefined,
  { rejectValue: string }
>('app/getOrganizationsAndSubSystemsForInfoSystemsThunk', async (_, { rejectWithValue }) => {
  const promiseBusinessSystems = new Promise<OrganizationAndSubSystemType[]>((resolve) =>
    resolve(serverApi.getBusinessSystemsForInfoSystems())
  );
  const promiseBusinessSolutions = new Promise<OrganizationAndSubSystemType[]>((resolve) =>
    resolve(serverApi.getBusinessSolutionsForInfoSystems())
  );
  const promiseTechnicalSystems = new Promise<OrganizationAndSubSystemType[]>((resolve) =>
    resolve(serverApi.getTechnicalSystemsForInfoSystems())
  );
  const promiseOrganizations = new Promise<OrganizationAndSubSystemType[]>((resolve) =>
    resolve(serverApi.getOrganizationsForInfoSystems())
  );
  const promiseBlocks = new Promise<OrganizationAndSubSystemType[]>((resolve) =>
    resolve(serverApi.getBlocksForInfoSystems())
  );
  return Promise.all([
    promiseBusinessSystems,
    promiseBusinessSolutions,
    promiseTechnicalSystems,
    promiseOrganizations,
    promiseBlocks,
  ])
    .then(([businessSystems, businessSolutions, technicalSystems, organizations, blocks]) => {
      return { businessSystems, businessSolutions, technicalSystems, organizations, blocks };
    })
    .catch((e) => {
      return rejectWithValue(getRejectedValue('Ошибка получения данных подсистем и организаций', e));
    });
});

export const createNewInfoSystemThunk = createAsyncThunk<InfoSystemType, InfoSystemType, { rejectValue: string }>(
  'app/createNewInfoSystemThunk',
  async (newInfoSystem, { rejectWithValue }) => {
    try {
      return await serverApi.createNewInfoSystem(newInfoSystem);
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка создания информационной системы', e));
    }
  }
);

export const deleteInfoSystemThunk = createAsyncThunk<InfoSystemType, string, { rejectValue: string }>(
  'app/deleteInfoSystemThunk',
  async (infoSystemUuid, { rejectWithValue }) => {
    try {
      return await serverApi.deleteInfoSystem(infoSystemUuid);
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка удаления информационной системы', e));
    }
  }
);

export const updateInfoSystemThunk = createAsyncThunk<InfoSystemType, InfoSystemType, { rejectValue: string }>(
  'app/updateInfoSystemThunk',
  async (newInfoSystem, { rejectWithValue }) => {
    try {
      return await serverApi.updateInfoSystem(newInfoSystem);
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка обновления информационной системы', e));
    }
  }
);

export const infoSystemsSlice = createSlice({
  name: 'infoSystemsSlice',
  initialState,
  reducers: {
    clearInfoSystemsError: (state) => {
      state.isErrorInfoSystems = undefined;
    },
    setSearchValueInfoSystems: (state, action) => {
      state.searchValueInfoSystems = action.payload;
    },
    setInfoSystemsTableColumns: (state, action) => {
      state.tableColumns = action.payload;
    },
    clearSnackBarItems: (state) => {
      state.snackBarItems = [];
    },
    deleteSnackBarItem: (state, action) => {
      state.snackBarItems = state.snackBarItems.filter((item) => item.key !== action.payload.key);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInfoSystemsThunk.fulfilled, (state, action) => {
        state.isLoadingInfoSystems = false;
        state.infoSystems = action.payload;
      })
      .addCase(getInfoSystemsThunk.pending, (state) => {
        state.infoSystems = undefined;
        state.isErrorInfoSystems = undefined;
        state.isLoadingInfoSystems = true;
      })
      .addCase(getInfoSystemsThunk.rejected, (state, action) => {
        state.isLoadingInfoSystems = false;
        state.isErrorInfoSystems = action.payload ? action.payload.toString() : 'Неизвестная ошибка - infoSystemsSlice';
      })

      .addCase(getOrganizationsAndSubSystemsForInfoSystemsThunk.fulfilled, (state, action) => {
        state.isLoadingSubSystems = false;
        state.organizationsAndSubSystems = action.payload;
      })
      .addCase(getOrganizationsAndSubSystemsForInfoSystemsThunk.pending, (state) => {
        state.isErrorSubSystems = undefined;
        state.isLoadingSubSystems = true;
      })
      .addCase(getOrganizationsAndSubSystemsForInfoSystemsThunk.rejected, (state, action) => {
        state.isLoadingSubSystems = false;
        state.isErrorSubSystems = action.payload
          ? action.payload.toString()
          : 'Неизвестная ошибка - infoSystemsSlice: SubSystems';
      })

      .addCase(createNewInfoSystemThunk.fulfilled, (state) => {
        state.isLoadingInfoSystems = false;
        state.snackBarItems = [
          {
            key: 1,
            message: 'Запись создана',
            status: 'success',
            autoClose: 5,
          },
        ];
      })
      .addCase(createNewInfoSystemThunk.pending, (state) => {
        state.isErrorInfoSystems = undefined;
        state.isLoadingInfoSystems = true;
      })
      .addCase(createNewInfoSystemThunk.rejected, (state) => {
        state.isLoadingInfoSystems = false;
        state.snackBarItems = [
          {
            key: 1,
            message: 'Ошибка создания',
            status: 'alert',
          },
        ];
      })

      .addCase(deleteInfoSystemThunk.fulfilled, (state) => {
        state.isLoadingInfoSystems = false;
        state.snackBarItems = [
          {
            key: 1,
            message: 'Система удалена',
            status: 'success',
            autoClose: 5,
          },
        ];
      })
      .addCase(deleteInfoSystemThunk.pending, (state) => {
        state.isErrorInfoSystems = undefined;
        state.isLoadingInfoSystems = true;
      })
      .addCase(deleteInfoSystemThunk.rejected, (state) => {
        state.isLoadingInfoSystems = false;
        state.snackBarItems = [
          {
            key: 1,
            message: 'Ошибка удаления',
            status: 'alert',
          },
        ];
      })
      .addCase(updateInfoSystemThunk.fulfilled, (state, action) => {
        state.isLoadingSubSystems = false;
        if (!!state.infoSystems?.data.length) {
          state.infoSystems.data = state.infoSystems.data.map((infoSystem) => {
            return infoSystem._uuid !== action.payload._uuid ? infoSystem : action.payload;
          });

          state.snackBarItems = [
            {
              key: 1,
              message: 'Изменения сохранены',
              status: 'success',
              autoClose: 5,
            },
          ];
        }
      })
      .addCase(updateInfoSystemThunk.pending, (state) => {
        state.isErrorSubSystems = undefined;
        state.isLoadingSubSystems = true;
      })
      .addCase(updateInfoSystemThunk.rejected, (state) => {
        state.isLoadingSubSystems = false;
        state.snackBarItems = [
          {
            key: 1,
            message: 'Ошибка сохранения',
            status: 'alert',
          },
        ];
      });
  },
});

export const {
  clearInfoSystemsError,
  setSearchValueInfoSystems,
  setInfoSystemsTableColumns,
  clearSnackBarItems,
  deleteSnackBarItem,
} = infoSystemsSlice.actions;

export const selectorInfoSystems = (state: RootState) => state.infoSystems.infoSystems;
export const selectorSearchValueInfoSystems = (state: RootState) => state.infoSystems.searchValueInfoSystems;
export const selectorIsLoadingInfoSystems = (state: RootState) => state.infoSystems.isLoadingInfoSystems;
export const selectorInfoSystemsTableColumns = (state: RootState) => state.infoSystems.tableColumns;
export const selectorSubSystems = (state: RootState) => state.infoSystems.organizationsAndSubSystems;
export const selectorIsLoadingSubSystems = (state: RootState) => state.infoSystems.isLoadingSubSystems;
export const selectorSnackBarItemsInfoSystems = (state: RootState) => state.infoSystems.snackBarItems;

export const selectorIsErrorInfoSystems = (state: RootState) => state.infoSystems.isErrorInfoSystems;
export const selectorIsErrorSubSystems = (state: RootState) => state.infoSystems.isErrorSubSystems;

export default infoSystemsSlice.reducer;
