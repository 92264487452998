import React from 'react';
import classes from './CardsListItem.module.css';

import { Text } from '@consta/uikit/Text';
import { Badge } from '@consta/uikit/Badge';

import iconTree from '../../../../../../../assets/CatalogPage/iconTree.svg';
import iconMore from '../../../../../../../assets/CatalogPage/iconMore.svg';

import { BookmarkType, CatalogItemType } from '../../../../../../../types/types';
import { useAppSelector } from '../../../../../../../hooks/hooks';
import { selectorCatalogActiveSection } from '../../../../../../../store/catalogSlice';
import { getBadgeColor } from '../../../../../../../utils/Catalog/getBadgeColor';
import StringWithSelectedTags from '../../../../../../../Components/StringWithSelectedTags/StringWithSelectedTags';
import { removeHTMLTags } from '../../../../../../../utils/Formatters/removeHTMLTags';
import CustomHoverToolTip from '../../../../../../../Components/CustomHoverToolTip/CustomHoverToolTip';
import { useExistedOrCreateNewBookmark } from '../../../../../../../hooks/useExistedOrCreateNewBookmark';

interface ICardsListItemProps {
  catalogItem: CatalogItemType;
}

const CardsListItem: React.FC<ICardsListItemProps> = ({ catalogItem }) => {
  const badgeColor = getBadgeColor(catalogItem.color_group);
  const catalogActiveSection = useAppSelector(selectorCatalogActiveSection);
  const doExistedOrCreateNewBookmark = useExistedOrCreateNewBookmark();

  const onClick = () => {
    if (catalogItem.name) {
      const bookmark = {
        label: removeHTMLTags(catalogItem.name),
        type: catalogActiveSection?.slug,
        slug: catalogActiveSection?.slug === 'system' ? 'system' : catalogItem.unified_object_slug,
        hash: catalogActiveSection?.slug === 'system' ? catalogItem._uuid : catalogItem._hash_key,
      } as BookmarkType;
      doExistedOrCreateNewBookmark(bookmark);
    }
  };

  if (catalogItem.unified_object_slug === 'system') {
    return (
      <div className={classes.container} onClick={onClick}>
        <div className={classes.topRow}>
          <StringWithSelectedTags name={catalogItem.name || ''} minLengthForTooltip={50} />
          {catalogItem.unified_object && (
            <Badge
              view="stroked"
              label={catalogItem.unified_object || undefined}
              style={{
                color: badgeColor,
                boxShadow: `inset 0 0 0 1px ${badgeColor}`,
              }}
            />
          )}
        </div>
        {catalogItem.description && <Text className={classes.descriptionSystem}>{catalogItem.description || ''}</Text>}
        <div className={classes.bottomRowSystem}>
          <div className={classes.destination}>
            {catalogItem.icon && (
              <img
                src={`data:image/svg+xml;base64,${catalogItem.icon}`}
                className={classes.destinationIco}
                alt="destinationIco"
              />
            )}
            {catalogItem.organization?.name?.length! > 35 ? (
              <CustomHoverToolTip title={catalogItem.organization?.name!}>
                <Text className={classes.destinationTitle}>{catalogItem.organization?.name ?? ''}</Text>
              </CustomHoverToolTip>
            ) : (
              <Text className={classes.destinationTitle}>{catalogItem.organization?.name ?? ''}</Text>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.container} onClick={onClick}>
      <div className={classes.topRow}>
        <StringWithSelectedTags name={catalogItem.name || ''} minLengthForTooltip={50} />
        {catalogItem.unified_object && (
          <Badge
            view="stroked"
            label={catalogItem.unified_object || undefined}
            style={{
              color: badgeColor,
              boxShadow: `inset 0 0 0 1px ${badgeColor}`,
            }}
          />
        )}
      </div>
      {catalogItem.description_name?.length! > 50 ? (
        <CustomHoverToolTip title={catalogItem.description_name!}>
          <Text className={classes.description}>{catalogItem.description_name || ''}</Text>
        </CustomHoverToolTip>
      ) : (
        <Text className={classes.description}>{catalogItem.description_name || ''}</Text>
      )}
      <div className={classes.bottomRow}>
        <div className={classes.hierarchy}>
          {catalogItem.level_1 && (
            <div className={classes.hierarchyRow}>
              <img src={iconTree} alt="hierarchy top" className={classes.ico} />
              {catalogItem.level_1?.length! > 45 ? (
                <CustomHoverToolTip title={catalogItem.level_1!}>
                  <Text className={classes.hierarchyText}>{catalogItem.level_1 || ''}</Text>
                </CustomHoverToolTip>
              ) : (
                <Text className={classes.hierarchyText}>{catalogItem.level_1 || ''}</Text>
              )}
            </div>
          )}
          {catalogItem.level_2 && (
            <div className={classes.hierarchyRow}>
              <img src={iconMore} alt="hierarchy first" className={classes.icoFirst} />
              {catalogItem.level_2?.length! > 45 ? (
                <CustomHoverToolTip title={catalogItem.level_2!}>
                  <Text className={classes.hierarchyText}>{catalogItem.level_2 || ''}</Text>
                </CustomHoverToolTip>
              ) : (
                <Text className={classes.hierarchyText}>{catalogItem.level_2 || ''}</Text>
              )}
            </div>
          )}
          {catalogItem.level_3 && (
            <div className={classes.hierarchyRow}>
              <img src={iconMore} alt="hierarchy second" className={classes.icoSecond} />
              {catalogItem.level_3?.length! > 45 ? (
                <CustomHoverToolTip title={catalogItem.level_3!}>
                  <Text className={classes.hierarchyText}>{catalogItem.level_3 || ''}</Text>
                </CustomHoverToolTip>
              ) : (
                <Text className={classes.hierarchyText}>{catalogItem.level_3 || ''}</Text>
              )}
            </div>
          )}
        </div>
        <div className={classes.destination}>
          {catalogItem.icon && (
            <img
              src={`data:image/svg+xml;base64,${catalogItem.icon}`}
              className={classes.destinationIco}
              alt="destinationIco"
            />
          )}
          {catalogItem.system?.name?.length! > 35 ? (
            <CustomHoverToolTip title={catalogItem.system?.name ?? ''}>
              <Text className={classes.destinationTitle}>{catalogItem.system?.name || ''}</Text>
            </CustomHoverToolTip>
          ) : (
            <Text className={classes.destinationTitle}>{catalogItem.system?.name || ''}</Text>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardsListItem;
