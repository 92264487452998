import React from 'react';
import { Navigate } from 'react-router-dom';

import MainPage from '../Pages/MainPage/MainPage';
import CatalogPage from '../Pages/CatalogPage/CatalogPage';
import PlaceHolderPage from '../Pages/PlaceHolderPage/PlaceHolderPage';
import InfoSystemsPage from '../Pages/InfoSystemsPage/InfoSystemsPage';
import ConnectionsPage from '../Pages/ConnectionsPage/ConnectionsPage';

export enum PageRoutesEnum {
  MainPage = '/main-page',
  CatalogPage = '/catalog-page',
  InfoSystemsPage = '/info-system-page',
  ConnectionsPage = '/connections-page',
  AdminPage = '/admin-page',
  AccessDeniedPage = '/access-denied-page',
}

export const onLogoClickRoute = PageRoutesEnum.MainPage;
export const appRedirectRoute = PageRoutesEnum.MainPage;

export const appRoutes = [
  {
    path: PageRoutesEnum.MainPage,
    element: <MainPage />,
  },
  {
    path: PageRoutesEnum.CatalogPage,
    element: <CatalogPage />,
  },
  {
    path: PageRoutesEnum.InfoSystemsPage,
    element: <InfoSystemsPage />,
  },
  {
    path: PageRoutesEnum.ConnectionsPage,
    element: <ConnectionsPage />,
  },
  {
    path: PageRoutesEnum.AdminPage,
    element: <PlaceHolderPage title={'Технические Метаданные - Администрирование системы'} />,
  },
  {
    path: PageRoutesEnum.AccessDeniedPage,
    element: (
      <PlaceHolderPage
        title={`\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0Доступ запрещен`}
        isHideComeSoon={true}
      />
    ),
  },
  { path: '*', element: <Navigate to={PageRoutesEnum.MainPage} replace /> },
];

// export enum ExternalRoutesEnum {}

export const withoutFooterRoutes = [
  PageRoutesEnum.CatalogPage,
  PageRoutesEnum.InfoSystemsPage,
  PageRoutesEnum.ConnectionsPage,
];
