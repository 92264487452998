import { useAppDispatch, useAppSelector } from './hooks';
import { getQuerySearchValue } from '../utils/getQuerySearchValue';
import { getQueryPaginationFromFirstPage } from '../utils/getQueryPaginationFromFirstPage';
import { combineQuery } from '../utils/combineQuery';

import {
  getConnectionsThunk,
  selectorConnections,
  selectorIsLoadingConnections,
  selectorSearchValueConnections,
} from '../store/connectionsSlice';
import { abortControllerConnections } from '../api/connectionsApi';
import { useCallback } from 'react';

export const useGetConnections = () => {
  const dispatch = useAppDispatch();
  const metaStore = useAppSelector(selectorConnections)?.meta;
  const searchValueStore = useAppSelector(selectorSearchValueConnections);
  const isLoadingConnections = useAppSelector(selectorIsLoadingConnections);

  return useCallback(
    ({ searchValue, queryPagination }: { searchValue?: string; queryPagination?: string }) => {
      const querySearchValue = getQuerySearchValue(
        searchValue ? searchValue : searchValue === '' ? '' : searchValueStore
      );

      const requestQuery = combineQuery([
        querySearchValue,
        queryPagination ? queryPagination : getQueryPaginationFromFirstPage(metaStore ? metaStore : undefined),
      ]);
      if (isLoadingConnections) {
        abortControllerConnections.abort();
        setTimeout(() => {
          dispatch(getConnectionsThunk(requestQuery));
        });
      } else {
        dispatch(getConnectionsThunk(requestQuery));
      }
    },
    [dispatch, metaStore, searchValueStore, isLoadingConnections]
  );
};
