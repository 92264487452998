import React from 'react';
import classes from './ConnectionAssetsList.module.css';
import ConnectionAssetsItem from './ConnectionAssetsItem/ConnectionAssetsItem';
import { CheckConnectionAssetType } from '../../api/serverResponse';
import { Text } from '@consta/uikit/Text';
import { ConnectionSettingsItemType } from '../../types/types';

interface ICreateConnectionAssetsList {
  assets: CheckConnectionAssetType[] | undefined;
  searchValue?: string | undefined;
  cardView?: string | null | undefined;
  onChangeConnectionAsset?: ({
    connectionAsset,
    assetSettings,
  }: {
    connectionAsset: CheckConnectionAssetType;
    assetSettings: ConnectionSettingsItemType[] | undefined;
  }) => void;
}

const ConnectionAssetsList: React.FC<ICreateConnectionAssetsList> = ({
  assets,
  searchValue,
  cardView,
  onChangeConnectionAsset,
}) => {
  const isOdata = cardView === 'odata';
  const isOracle = cardView === 'oracle';
  const isDb = !isOdata && !isOracle;

  const assetItems = searchValue
    ? assets?.filter((asset) => asset.name?.toLowerCase().includes(searchValue.toLowerCase()))
    : assets;

  return isDb ? (
    <div className={classes.container}>
      {!!assetItems?.length ? (
        assetItems.map((asset, ind) => (
          <ConnectionAssetsItem key={ind} connectionAsset={asset} onChangeConnectionAsset={onChangeConnectionAsset} />
        ))
      ) : (
        <Text className={classes.title}>{'Ничего не найдено'}</Text>
      )}
    </div>
  ) : null;
};

export default ConnectionAssetsList;
