import { DataRangeType } from '../Components/FilterData/FilterData';
import { formatDateDDMMYYYY } from './formatDateDDMMYYYY';

export const getFormattedDataRange = (filterDataRange: DataRangeType | undefined) => {
  let dataRangeStr = '';
  if (filterDataRange?.[0]) {
    dataRangeStr += formatDateDDMMYYYY(filterDataRange[0]);
  }
  if (filterDataRange?.[1]) {
    dataRangeStr += '-' + formatDateDDMMYYYY(filterDataRange[1]);
  }
  return dataRangeStr;
};
