import React, { useEffect } from 'react';

import classes from './ConnectionsTable.module.css';

import { CONNECTIONS_TABLE_COLUMNS } from '../../../../../../Templates/connectionsTableColumns';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import {
  selectorConnectionsTableActiveRowUuid,
  selectorTableColumnsConnections,
  setTableConnectionsActiveRowUuid,
  setTableColumnsConnections,
  setTableConnectionsHoveredRowUuid,
} from '../../../../../../store/connectionsSlice';
import { useColumnsStore } from '../../../../../../hooks/useColumnsStore';
import { getShortColumns } from '../../../../../../shared-front/Table/utils/getShortColumns';
import TableWrapper from '../../../../../../Components/TableWrapper/TableWrapper';
import { Table } from '../../../../../../shared-front/Table/Table';
import { getShowColumns } from '../../../../../../shared-front/Table/utils/getShowColumns';
import { LeftSideBarHandleType } from '../../../../../../shared-front/LeftSideBar/LeftSideBar';
import { CPRightSideBarEnum } from '../../../../ConnectionsPage';
import { useGetConnectionsTableData } from '../../../../../../hooks/useGetConnectionsTableData';
import { clearCreateNewConnection } from '../../../../../../store/createNewConnectionSlice';

interface IConnectionsTableProps {
  refSidebar: React.RefObject<LeftSideBarHandleType>;
  setRightSideBarMode: (mode: CPRightSideBarEnum | undefined) => void;
}

const columnsTemplate = CONNECTIONS_TABLE_COLUMNS;

const ConnectionsTable: React.FC<IConnectionsTableProps> = ({ refSidebar, setRightSideBarMode }) => {
  const dispatch = useAppDispatch();
  const getConnectionsTableData = useGetConnectionsTableData();
  const columnsStore = useAppSelector(selectorTableColumnsConnections);
  const connectionsTableActiveRowUuid = useAppSelector(selectorConnectionsTableActiveRowUuid);

  const columns = useColumnsStore({ columnsStore, columnsTemplate });

  const tableData = getConnectionsTableData();

  useEffect(() => {
    dispatch(setTableColumnsConnections(getShortColumns(columnsTemplate)));
  }, [dispatch]);

  const onRowClick = (originalRow: { [key: string]: unknown }) => {
    if (!(originalRow.rowId as string).includes('innerRow')) {
      dispatch(clearCreateNewConnection());
      dispatch(setTableConnectionsActiveRowUuid(originalRow.rowId as string));
      setRightSideBarMode(CPRightSideBarEnum.DisplayConnection);
      const isSidebarOpen = refSidebar.current?.getIsSidebarOpen();
      if (!isSidebarOpen) {
        refSidebar.current?.openSidebar();
      }
    }
  };

  const onToggleRowHover = (originalRow: { [key: string]: unknown } | undefined) => {
    dispatch(setTableConnectionsHoveredRowUuid(originalRow?.rowId as string));
  };

  return (
    <div className={classes.container}>
      <TableWrapper columns={columns} height={'100%'}>
        <Table
          data={tableData}
          columns={getShowColumns(columns)}
          onRowClick={onRowClick}
          isColumnsResizable={true}
          isRowHover={true}
          highlightedRowId={tableData.find((row) => row.rowId === connectionsTableActiveRowUuid)?.id}
          onToggleRowHover={onToggleRowHover}
        />
      </TableWrapper>
    </div>
  );
};

export default ConnectionsTable;
