import React, { useEffect, useState } from 'react';

import classes from './CustomTextField.module.css';
import { TextField } from '@consta/uikit/TextField';
import { Text } from '@consta/uikit/Text';

import iconInfoCircle from '../../assets/CatalogPage/iconInfoCircle.svg';
import iconEditBlue from '../../assets/CatalogPage/iconEditBlue.svg';
import { Button } from '@consta/uikit/Button';
import CustomHoverToolTip from '../CustomHoverToolTip/CustomHoverToolTip';
import CustomBlueTextButton from '../CustomBlueTextButton/CustomBlueTextButton';
import { Loader } from '@consta/uikit/Loader';
interface ICustomTextFieldProps {
  title: string;
  onSave: (value: string) => void;
  value?: string;
  placeholder?: string;
  isSaving?: boolean;
  isModeEdit?: boolean;
  onIsModeEditChange?: (isModeEdit: boolean) => void;
  onChangeValue?: (value: string) => void;
}

const CustomTextField: React.FC<ICustomTextFieldProps> = ({
  title,
  value,
  placeholder = '...',
  onSave,
  isSaving,
  isModeEdit = false,
  onIsModeEditChange,
  onChangeValue,
}) => {
  const [isEditMode, setIsEditMode] = useState(isModeEdit);
  const [valueLocal, setValueLocal] = useState<string>(value ? value : '');
  const [isSavingLocal, setIsSavingLocal] = useState(false);

  const handleChange = ({ value }: { value: string | null }) => {
    if (onChangeValue) {
      onChangeValue(value ? value : '');
    } else {
      setValueLocal(value ? value : '');
    }
  };

  const clickIsEditMode = () => {
    if (onIsModeEditChange) {
      onIsModeEditChange(true);
    } else {
      setIsEditMode(true);
    }
  };

  const handlerCancel = () => {
    setValueLocal(value ? value : '');
    if (onIsModeEditChange) {
      onIsModeEditChange(false);
    } else {
      setIsEditMode(false);
    }
  };

  const handlerSave = () => {
    onSave(valueLocal);
    setIsSavingLocal(true);
  };

  useEffect(() => {
    if (isSavingLocal && !isSaving) {
      setIsSavingLocal(false);
      if (onIsModeEditChange) {
        onIsModeEditChange(false);
      } else {
        setIsEditMode(false);
      }
    }
  }, [isSaving, isSavingLocal, onIsModeEditChange]);

  useEffect(() => {
    setIsEditMode(isModeEdit);
  }, [isModeEdit]);

  useEffect(() => {
    setValueLocal(value ? value : '');
  }, [value]);

  return (
    <div className={classes.container}>
      <div className={classes.titleRow}>
        <Text className={classes.title}>{title}</Text>
        <CustomHoverToolTip title={`Пользовательское описание.\nДоступно для общего редактирования`}>
          <img src={iconInfoCircle} className={classes.ico} alt={'infoBtn'} />
        </CustomHoverToolTip>
        {!isEditMode && (
          <div className={classes.buttonEdit}>
            <Button
              size={'xs'}
              label="edit"
              view="clear"
              onlyIcon
              iconRight={() => <img src={iconEditBlue} alt="editIco" />}
              onClick={clickIsEditMode}
            />
          </div>
        )}
      </div>
      {isEditMode ? (
        <>
          <TextField
            onChange={handleChange}
            value={valueLocal}
            type="textarea"
            cols={95}
            rows={6}
            placeholder={placeholder}
            size={'s'}
          />
          <div className={classes.buttonsRow}>
            {isSaving ? (
              <div className={classes.loaderContainer}>
                <div className={classes.loaderText}>Сохранение</div>
                <div>
                  <Loader size={'xs'} className={classes.loader} />
                </div>
              </div>
            ) : (
              <>
                <CustomBlueTextButton title={'Отменить'} onClick={handlerCancel} />
                <CustomBlueTextButton title={'Сохранить'} onClick={handlerSave} />
              </>
            )}
          </div>
        </>
      ) : (
        <>
          {valueLocal ? (
            <Text className={classes.value}>{valueLocal}</Text>
          ) : (
            <Text className={classes.placeholder}>{placeholder}</Text>
          )}
        </>
      )}
    </div>
  );
};

export default CustomTextField;
