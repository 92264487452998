import { instanceAxios } from './instanceAxios';
import { InfoSystemsServerResponseType } from './serverResponse';
import { InfoSystemType, OrganizationAndSubSystemType } from '../types/types';

export let abortControllerInfoSystems = new AbortController();

export const infoSystemsApi = {
  async getInfoSystems(requestQuery: string) {
    return instanceAxios
      .get<InfoSystemsServerResponseType>(`/dictionaries/system${requestQuery ? requestQuery : ''}`)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.name === 'CanceledError') {
          abortControllerInfoSystems = new AbortController();
          return {
            data: [],
            meta: { from: 0, to: 0, total: 0, per_page: 0, current_page: 0, last_page: 0 },
          } as InfoSystemsServerResponseType;
        } else {
          throw new Error(e.message);
        }
      });
  },

  async getBusinessSystemsForInfoSystems() {
    const response = await instanceAxios.get<{ data: OrganizationAndSubSystemType[] }>(`/dictionaries/business-system`);
    return response.data.data;
  },

  async getBusinessSolutionsForInfoSystems() {
    const response = await instanceAxios.get<{ data: OrganizationAndSubSystemType[] }>(
      `/dictionaries/business-solution`
    );
    return response.data.data;
  },

  async getTechnicalSystemsForInfoSystems() {
    const response = await instanceAxios.get<{ data: OrganizationAndSubSystemType[] }>(
      `/dictionaries/technical-system`
    );
    return response.data.data;
  },

  async getOrganizationsForInfoSystems() {
    const response = await instanceAxios.get<{ data: OrganizationAndSubSystemType[] }>(`/dictionaries/organization`);
    return response.data.data;
  },

  async getBlocksForInfoSystems() {
    const response = await instanceAxios.get<{ data: OrganizationAndSubSystemType[] }>(`/dictionaries/block`);
    return response.data.data;
  },

  async createNewInfoSystem(newInfoSystem: InfoSystemType) {
    const response = await instanceAxios.post<InfoSystemType>(`/dictionaries/system`, newInfoSystem);
    return response.data;
  },

  async deleteInfoSystem(infoSystemUuid: string) {
    const response = await instanceAxios.delete<InfoSystemType>(`/dictionaries/system/${infoSystemUuid}`);
    return response.data;
  },

  async updateInfoSystem(infoSystem: InfoSystemType) {
    const response = await instanceAxios.put<{ data: InfoSystemType }>(
      `/dictionaries/system/${infoSystem._uuid}`,
      infoSystem
    );
    return response.data.data;
  },
};
