import React from 'react';

import classes from './CardAttributeContent.module.css';
import InfoAttributeRow from './InfoAttributeRow/InfoAttributeRow';
import { BookmarkType } from '../../../../types/types';
import CardTabsRow, { CardTabType } from '../../../../Components/CardTabsRow/CardTabsRow';
import ContentAttributeByTabs from './ContentAttributeByTabs/ContentAttributeByTabs';
import { useActiveTab } from '../../../../hooks/useActiveTab';

interface ICardSchemaContentProps {
  bookmark: BookmarkType;
}

export const TABS_ATTRIBUTE: CardTabType[] = [{ id: 0, label: 'Общая информация' }];

const CardAttributeContent: React.FC<ICardSchemaContentProps> = ({ bookmark }) => {
  const [activeTab, setActiveTab] = useActiveTab({ bookmark, tabs: TABS_ATTRIBUTE });

  return (
    <div className={classes.container}>
      <InfoAttributeRow bookmark={bookmark} />
      <CardTabsRow tabs={TABS_ATTRIBUTE} activeTab={activeTab} setActiveTab={setActiveTab} />
      <ContentAttributeByTabs activeTab={activeTab} bookmark={bookmark} />
    </div>
  );
};

export default CardAttributeContent;
