import React from 'react';

import classes from './EditConnectionGeneral.module.css';
import { Text } from '@consta/uikit/Text';
import RightSideBarTextField from '../../../../../../Components/RightSideBarTextField/RightSideBarTextField';
import { TextField } from '@consta/uikit/TextField';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import {
  selectorEditConnection,
  selectorIsRequiredEditConnectionName,
  setEditConnectionDescription,
  setEditConnectionName,
} from '../../../../../../store/editConnectionSlice';

interface IEditConnectionGeneralProps {}

const EditConnectionGeneral: React.FC<IEditConnectionGeneralProps> = () => {
  const dispatch = useAppDispatch();
  const editConnection = useAppSelector(selectorEditConnection);
  const isRequiredEditConnectionName = useAppSelector(selectorIsRequiredEditConnectionName);

  const onChangeEditConnectionName = (val: string | null) => {
    const valFiltered = val ? val.replace(/[^a-z0-9._]/g, '') : null;
    dispatch(setEditConnectionName(valFiltered));
  };

  const onChangeEditConnectionDescription = ({ value }: { value: string | null }) => {
    dispatch(setEditConnectionDescription(value));
  };

  return (
    <div className={classes.container}>
      <div className={classes.generalSection}>
        <RightSideBarTextField
          required={true}
          label={'Наименование подключения'}
          value={editConnection?.name}
          onChange={onChangeEditConnectionName}
          placeholder={'Наименование подключения'}
          isRequired={isRequiredEditConnectionName}
        />
        <TextField
          label={'Описание'}
          onChange={onChangeEditConnectionDescription}
          value={editConnection?.description}
          type="textarea"
          rows={7}
          placeholder="Описание"
          style={{ marginBottom: '24px' }}
        />
        <div className={classes.sectionItem}>
          <Text className={classes.sectionItemTitle}>{`Интегрируемая система`}</Text>
          <Text className={classes.sectionItemValue}>{editConnection?.system?.name ?? '-'}</Text>
        </div>
        <div className={classes.sectionItem}>
          <Text className={classes.sectionItemTitle}>{`Тип источника`}</Text>
          <div className={classes.row}>
            {editConnection?.source_type?.icon && (
              <img
                src={`data:image/svg+xml;base64,${editConnection.source_type.icon}`}
                className={classes.ico}
                alt="source type icon"
              />
            )}
            <Text className={classes.sectionItemValue}>{editConnection?.source_type?.name ?? '-'}</Text>
          </div>
        </div>
        <div className={classes.sectionItem}>
          <Text className={classes.sectionItemTitle}>{`Тип подключения`}</Text>
          <Text className={classes.sectionItemValue}>{editConnection?.connector_type?.name ?? '-'}</Text>
        </div>
        <div className={classes.sectionItem}>
          <Text className={classes.sectionItemTitle}>{`Версия источника`}</Text>
          <Text className={classes.sectionItemValue}>{editConnection?.source_version ?? '-'}</Text>
        </div>
      </div>
    </div>
  );
};

export default EditConnectionGeneral;
