import React from 'react';

import classes from './Odata1CObjectCommonInfoTab.module.css';
import CollapseBlock from '../../../../../../Components/CollapseBlock/CollapseBlock';
import CardsContentBlock from '../../../../../../Components/CardsContentBlock/CardsContentBlock';
import SystemCard from '../../../../../../Components/SystemCard/SystemCard';
import { Bookmark1CObject, BookmarkType } from '../../../../../../types/types';
import CardInputLongDescription from '../../../../../../Components/CardInputLongDescription/CardInputLongDescription';

interface IRdbCommonInfoTabProps {
  bookmark: BookmarkType;
}

const Odata1CObjectCommonInfoTab: React.FC<IRdbCommonInfoTabProps> = ({ bookmark }) => {
  const data = bookmark.data as Bookmark1CObject | undefined;

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        <CollapseBlock title={'Основное'} isOpen={true}>
          <CardInputLongDescription bookmark={bookmark} />
        </CollapseBlock>
        <CollapseBlock title={'Дополнительно'} isOpen={true}>
          <CardsContentBlock
            title={'Наименование объекта одата'}
            description={data?.odata_name ? data.odata_name : '-'}
          />
        </CollapseBlock>
      </div>
      <div className={classes.rightColumn}>
        <SystemCard
          systemUuid={data?.system._uuid}
          systemName={data?.system.name}
          systemSourceIco={data?.source_icon}
          systemSourceType={data?.source_type}
        />
      </div>
    </div>
  );
};

export default Odata1CObjectCommonInfoTab;
