import React from 'react';

import classes from './ConnectionAssetsItem.module.css';
import CollapseBlock from '../../CollapseBlock/CollapseBlock';
import CreateConnectionItemSettings from '../../../Pages/ConnectionsPage/CPRightSideBar/CPRightSideBarCreateConnection/CreateProgressStepSelector/CreateConnectionStepFour/CreateConnectionItemSettings/CreateConnectionItemSettings';
import { ConnectionSettingsItemType } from '../../../types/types';
import { CheckConnectionAssetType } from '../../../api/serverResponse';

interface ICreateConnectionAssetsItemProps {
  connectionAsset: CheckConnectionAssetType;
  onChangeConnectionAsset?: ({
    connectionAsset,
    assetSettings,
  }: {
    connectionAsset: CheckConnectionAssetType;
    assetSettings: ConnectionSettingsItemType[] | undefined;
  }) => void;
}

const ConnectionAssetsItem: React.FC<ICreateConnectionAssetsItemProps> = ({
  connectionAsset,
  onChangeConnectionAsset,
}) => {
  const setAssetSettingsHandler = (assetSettings: ConnectionSettingsItemType[] | undefined) => {
    onChangeConnectionAsset && onChangeConnectionAsset({ connectionAsset, assetSettings });
  };

  return (
    <div className={classes.assetContainer}>
      <CollapseBlock
        title={connectionAsset.name ?? ''}
        isOpen={false}
        isShortContainer={true}
        isShortChildrenContainer={true}
      >
        <CreateConnectionItemSettings
          connectionSettings={connectionAsset.settings}
          setConnectionSettings={setAssetSettingsHandler}
        />
      </CollapseBlock>
    </div>
  );
};

export default ConnectionAssetsItem;
