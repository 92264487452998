import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import {
  selectorCatalogDataFilters,
  selectorCatalogGroupFilters,
  selectorCatalogMultiFilters,
  setDataFiltersStore,
  setGroupFiltersStore,
  setMultiFiltersStore,
} from '../../../../../store/catalogSlice';

import { getActiveMultiFilterTags } from '../../../../../utils/getActiveMultiFilters';
import { getActiveDataFilterTags } from '../../../../../utils/getActiveDataFilters';
import { getActiveGroupFilterTags } from '../../../../../utils/getActiveGroupFilters';
import { getFormattedDataRange } from '../../../../../utils/getFormattedDataRange';
import { useGetTechMetaDocuments } from '../../../../../hooks/useGetTechMetaDocuments';
import FilterBar, { FilterBarItemType } from '../../../../../shared-front/FilterBar/FilterBar';
import { getNewActiveMultiFiltersByFilterBarTags } from '../../../../../utils/getNewActiveMultiFiltersByFilterBarTags';
import { getNewActiveDataFiltersByFilterBarTags } from '../../../../../utils/getNewActiveDataFiltersByFilterBarTags';
import { dataFiltersDateToDataFiltersStr } from '../../../../../utils/dataFiltersDateToDataFiltersStr';
import { getNewActiveGroupFiltersByFilterBarTags } from '../../../../../utils/getNewActiveGroupFiltersByFilterBarTags';

const ActiveFiltersRow: React.FC = () => {
  const dispatch = useAppDispatch();
  const getTechMetaDocuments = useGetTechMetaDocuments();
  const multiFilters = useAppSelector(selectorCatalogMultiFilters);
  const dataFilters = useAppSelector(selectorCatalogDataFilters);
  const groupFilters = useAppSelector(selectorCatalogGroupFilters);

  const activeMultiFiltersTag = getActiveMultiFilterTags(multiFilters);
  const activeDataFilterTags = getActiveDataFilterTags(dataFilters);
  const activeGroupFilterTags = getActiveGroupFilterTags(groupFilters);

  const activeMultiFiltersItems = activeMultiFiltersTag.map((multiFilter) => ({
    uuid: `multiFilter-*-${multiFilter.itemUuid}`,
    title: multiFilter.itemValue!,
  }));

  const activeDataFilterItems = activeDataFilterTags.map((dataFilter) => ({
    uuid: `dataFilter-*-${dataFilter.filterName}`,
    title: `${dataFilter.filterTile}: ${getFormattedDataRange(dataFilter.filterDataRange)}`,
  }));

  const activeGroupFilterItems = activeGroupFilterTags.map((groupFilter) => ({
    uuid: `groupFilter-*-${groupFilter.filterName}`,
    title: `${groupFilter.filterTile}`,
  }));

  const onChangeFilterBar = (tagItems: FilterBarItemType[]) => {
    const newMultiFilters = getNewActiveMultiFiltersByFilterBarTags(multiFilters, tagItems);
    dispatch(setMultiFiltersStore(newMultiFilters));

    const newDataFilters = getNewActiveDataFiltersByFilterBarTags(dataFilters, tagItems);
    dispatch(setDataFiltersStore(dataFiltersDateToDataFiltersStr(newDataFilters)));

    const newGroupFilters = getNewActiveGroupFiltersByFilterBarTags(groupFilters, tagItems);
    dispatch(setGroupFiltersStore(newGroupFilters));

    getTechMetaDocuments({
      multiFilters: newMultiFilters,
      dataFilters: newDataFilters,
      groupFilters: newGroupFilters,
    });
  };

  return (
    <FilterBar
      items={[...activeMultiFiltersItems, ...activeDataFilterItems, ...activeGroupFilterItems]}
      onSave={onChangeFilterBar}
    />
  );
};

export default ActiveFiltersRow;
