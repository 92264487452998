import React from 'react';

import classes from './CreateConnectionStepTwo.module.css';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import {
  clearNewConnectionAssetsForStepTwo,
  clearNewConnectionSelectedAssetsForStepTwo,
  selectorActiveSourceType,
  selectorConnectionInstance,
  selectorConnectionPassword,
  selectorConnectionPort,
  selectorConnectionUrl,
  selectorConnectionUser,
  selectorIpAddress,
  selectorIsNewConnectionChecked,
  selectorIsRequiredConnectionInstance,
  selectorIsRequiredConnectionPassword,
  selectorIsRequiredConnectionPort,
  selectorIsRequiredConnectionUrl,
  selectorIsRequiredConnectionUser,
  selectorIsRequiredIpAddress,
  selectorIsRequiredSelectedAssets,
  selectorNewConnectionAssets,
  selectorNewConnectionSelectedAssets,
  selectorReturnFromStepIndex,
  setConnectionInstance,
  setConnectionPassword,
  setConnectionPort,
  setConnectionUrl,
  setConnectionUser,
  setIpAddress,
  setNewConnectionSelectedAssets,
} from '../../../../../../store/createNewConnectionSlice';
import CreateConnectionAssetsCombobox from './CreateConnectionAssetsCombobox/CreateConnectionAssetsCombobox';
import CreateConnectionCheckNewConnectionRow from './CreateConnectionCheckNewConnectionRow/CreateConnectionCheckNewConnectionRow';
import ConnectionSettingsForm from '../../../ConnectionSettingsForm/ConnectionSettingsForm';
import { ItemComboboxType } from '../../../../../../types/types';

interface ICreateConnectionStepTwoDbProps {}

const CreateConnectionStepTwo: React.FC<ICreateConnectionStepTwoDbProps> = () => {
  const valueIpAddress = useAppSelector(selectorIpAddress);
  const isRequiredIpAddress = useAppSelector(selectorIsRequiredIpAddress);
  const valueConnectionPort = useAppSelector(selectorConnectionPort);
  const isRequiredConnectionPort = useAppSelector(selectorIsRequiredConnectionPort);
  const valueConnectionUser = useAppSelector(selectorConnectionUser);
  const isRequiredConnectionUser = useAppSelector(selectorIsRequiredConnectionUser);
  const valueConnectionPassword = useAppSelector(selectorConnectionPassword);
  const isRequiredConnectionPassword = useAppSelector(selectorIsRequiredConnectionPassword);
  const valueConnectionInstance = useAppSelector(selectorConnectionInstance);
  const isRequiredConnectionInstance = useAppSelector(selectorIsRequiredConnectionInstance);
  const valueConnectionUrl = useAppSelector(selectorConnectionUrl);
  const isRequiredConnectionUrl = useAppSelector(selectorIsRequiredConnectionUrl);
  const activeSourceType = useAppSelector(selectorActiveSourceType);
  const isNewConnectionChecked = useAppSelector(selectorIsNewConnectionChecked);
  const newConnectionAssets = useAppSelector(selectorNewConnectionAssets);
  const newConnectionSelectedAssets = useAppSelector(selectorNewConnectionSelectedAssets);
  const isRequiredSelectedAssets = useAppSelector(selectorIsRequiredSelectedAssets);
  const returnFromStepIndex = useAppSelector(selectorReturnFromStepIndex);

  const dispatch = useAppDispatch();

  const onChangeIpAddress = (value: string | null) => {
    dispatch(setIpAddress(value));
  };

  const onChangeConnectionPort = (value: string | null) => {
    dispatch(setConnectionPort(value));
  };

  const onChangeConnectionUser = (value: string | null) => {
    dispatch(setConnectionUser(value));
  };

  const onChangeConnectionPassword = (value: string | null) => {
    dispatch(setConnectionPassword(value));
  };

  const onChangeConnectionInstance = (value: string | null) => {
    dispatch(setConnectionInstance(value));
  };

  const onChangeConnectionUrl = (value: string | null) => {
    dispatch(setConnectionUrl(value));
  };

  const onChangeAssets = (value: ItemComboboxType[] | null) => {
    dispatch(setNewConnectionSelectedAssets(value));
  };

  const onConfirmAssetsModal = () => {
    dispatch(clearNewConnectionSelectedAssetsForStepTwo());
  };

  const onConfirmSettingsFormModal = () => {
    dispatch(clearNewConnectionAssetsForStepTwo());
  };

  return (
    <div className={classes.container}>
      <ConnectionSettingsForm
        valueIpAddress={valueIpAddress}
        onChangeIpAddress={onChangeIpAddress}
        isRequiredIpAddress={isRequiredIpAddress}
        valueConnectionPort={valueConnectionPort}
        onChangeConnectionPort={onChangeConnectionPort}
        isRequiredConnectionPort={isRequiredConnectionPort}
        valueConnectionUser={valueConnectionUser}
        onChangeConnectionUser={onChangeConnectionUser}
        isRequiredConnectionUser={isRequiredConnectionUser}
        valueConnectionPassword={valueConnectionPassword}
        onChangeConnectionPassword={onChangeConnectionPassword}
        isRequiredConnectionPassword={isRequiredConnectionPassword}
        valueConnectionInstance={valueConnectionInstance}
        onChangeConnectionInstance={onChangeConnectionInstance}
        isRequiredConnectionInstance={isRequiredConnectionInstance}
        valueConnectionUrl={valueConnectionUrl}
        onChangeConnectionUrl={onChangeConnectionUrl}
        isRequiredConnectionUrl={isRequiredConnectionUrl}
        isConnectionChecked={isNewConnectionChecked}
        cardView={activeSourceType?.card_view}
        onConfirmModal={onConfirmSettingsFormModal}
      />
      <CreateConnectionCheckNewConnectionRow />
      <CreateConnectionAssetsCombobox
        isConnectionChecked={isNewConnectionChecked}
        cardView={activeSourceType?.card_view}
        connectionAssets={newConnectionAssets}
        onChangeAssets={onChangeAssets}
        connectionSelectedAssets={newConnectionSelectedAssets}
        returnFromStepIndex={returnFromStepIndex}
        isRequiredSelectedAssets={isRequiredSelectedAssets}
        onConfirmAssetsModal={onConfirmAssetsModal}
      />
    </div>
  );
};

export default CreateConnectionStepTwo;
