import React, { useEffect } from 'react';

import classes from './CPRightSideBarEditConnection.module.css';
import ConnectionStepSelector, { StepSelectorItemType } from '../ConnectionStepSelector/ConnectionStepSelector';
import { useDisplayConnection } from '../../../../hooks/useDisplayConnection';
import { Loader } from '@consta/uikit/Loader';
import EditConnectionBottomButtons from './EditConnectionBottomButtons/EditConnectionBottomButtons';
import EditConnectionContentSelector from './EditConnectionContentSelector/EditConnectionContentSelector';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import { validateSettingsForEditConnection } from '../../../../utils/Connections/validateSettingsForEditConnection';
import {
  clearEditConnection,
  selectorEditConnection,
  setEditConnection,
  setFullAssetsList,
  setGeneralValidationFlags,
  setSettingsValidationFlags,
} from '../../../../store/editConnectionSlice';
import { validateGeneralForEditConnection } from '../../../../utils/Connections/validateGeneralForEditConnection';
import { CPRightSideBarEnum } from '../../ConnectionsPage';
import { useSaveEditConnection } from '../../../../hooks/useSaveEditConnection';

interface ICPRightSideBarEditConnectionProps {
  stepSelectorValue: StepSelectorItemType | null;
  setStepSelectorValue: (value: StepSelectorItemType | null) => void;
  setRightSideBarMode: (mode: CPRightSideBarEnum | undefined) => void;
  closeSideBar: () => void;
}

const CPRightSideBarEditConnection: React.FC<ICPRightSideBarEditConnectionProps> = ({
  closeSideBar,
  stepSelectorValue,
  setStepSelectorValue,
  setRightSideBarMode,
}) => {
  const dispatch = useAppDispatch();
  const editConnection = useAppSelector(selectorEditConnection);

  const { displayConnection, connectionDetail, getConnectionDetail, isLoadingConnectionDetail } =
    useDisplayConnection(closeSideBar);

  const { isSavingEditedConnection, saveEditedConnection } = useSaveEditConnection(setRightSideBarMode);

  useEffect(() => {
    if (displayConnection?._uuid !== connectionDetail?._uuid) {
      if (!isLoadingConnectionDetail) {
        getConnectionDetail();
      }
    }
  }, [connectionDetail?._uuid, displayConnection?._uuid, getConnectionDetail, isLoadingConnectionDetail]);

  useEffect(() => {
    if (connectionDetail) {
      dispatch(clearEditConnection());
      dispatch(setEditConnection({ ...connectionDetail, user: '', password: '' }));
      dispatch(setFullAssetsList(connectionDetail.assets));
    }
  }, [dispatch, connectionDetail]);

  const onCancelEditConnection = () => {
    setRightSideBarMode(CPRightSideBarEnum.DisplayConnection);
  };

  const onClickSaveEditConnection = () => {
    if (stepSelectorValue?.id === 'general') {
      if (editConnection) {
        const validationErrors = validateGeneralForEditConnection(editConnection);
        if (validationErrors) {
          dispatch(setGeneralValidationFlags(validationErrors));
        } else {
          saveEditedConnection();
        }
      }
    } else if (stepSelectorValue?.id === 'settings') {
      if (editConnection) {
        const validationErrors = validateSettingsForEditConnection(editConnection);
        if (validationErrors) {
          dispatch(setSettingsValidationFlags(validationErrors));
        } else {
          saveEditedConnection();
        }
      }
    } else {
      saveEditedConnection();
    }
  };

  if (isSavingEditedConnection) {
    return (
      <div className={classes.container}>
        <Loader style={{ marginTop: '50%' }} />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <ConnectionStepSelector stepSelectorValue={stepSelectorValue} setStepSelectorValue={setStepSelectorValue} />
      {isLoadingConnectionDetail ? (
        <Loader style={{ marginTop: '50%' }} />
      ) : (
        editConnection && (
          <>
            <EditConnectionContentSelector stepSelectorValue={stepSelectorValue} />
            <EditConnectionBottomButtons onSave={onClickSaveEditConnection} onCancel={onCancelEditConnection} />
          </>
        )
      )}
    </div>
  );
};

export default CPRightSideBarEditConnection;
